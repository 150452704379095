<template>
  <div class="page flex-col">
    <div class="section_15 flex-col">
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
      />
      <span class="text_30">关键技术</span>
      <span class="text_2">KEY&nbsp;TECHNOLOGY</span>
      <div class="box_15 flex-col">
        <div class="group_151 flex-row">
          <img
            class="image_37"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
          />
          <!-- <span class="text_65">首页</span>
          <span class="text_66">关键技术</span>
          <span class="text_67">产品中心</span>
          <span class="text_68">应用案例</span>
          <span class="text_69">下载中心</span>
          <span class="text_70">关于我们</span> -->

          <span class="text_65"><router-link :to="{ path: '/lanhu_shouyebeifen' }">首页</router-link></span>
          <span class="text_66"><router-link :to="{ path: '/lanhu_guanjianjishu' }">关键技术</router-link></span>
          <span class="text_67"><router-link :to="{ path: '/lanhu_chanpinzhongxin' }">产品中心</router-link></span>
          <span class="text_68"><router-link :to="{ path: '/lanhu_yingyonganli' }">应用案例</router-link></span>
          <span class="text_69"><router-link :to="{ path: '/lanhu_xiazaizhongxin' }">下载中心</router-link></span>
          <span class="text_70"><router-link :to="{ path: '/lanhu_guanyuwomen' }">关于我们</router-link></span>


          <img
            class="label_78"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
          />
          <span class="text_84">400-123-8888</span>
          <img
            class="label_79"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
          />
          <span class="text_85" @click="goToUrl('https://www.xzeai.com:6090')">运维管理平台</span>
        </div>
        <div class="image-wrapper_221 flex-row">
          <img
            class="image_38"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
          />
        </div>
      </div>
    </div>
    <div class="group_152 flex-col">
      <span class="text_1">边缘AI技术</span>
      <span class="text_10">
        基于物联网、人工智能、边缘计算技术研制，在机械水表上拍照识别，采集水量数据，通过物联网技术实现数据的可靠传输。采用云、边、端抄表器协同开发，针对不同的应用场景，分别推出了云端AI抄表器、边缘AI抄表器，矩阵式AI抄表器三个系列产品。
      </span>
      <div class="group_153 flex-row">
        <div class="group_154 flex-col justify-between">
          <div class="text-wrapper_203 flex-col"><span class="text_451">轻量化视觉AI算法</span></div>
          <div class="text-wrapper_204 flex-col"><span class="text_452">识别准确率：大于99%</span></div>
        </div>
        <div class="group_155 flex-col"></div>
        <div class="group_156 flex-col justify-between">
          <div class="text-wrapper_205 flex-col"><span class="text_453">算力：0.2GOPS</span></div>
          <div class="text-wrapper_206 flex-col"><span class="text_454">算法运行时间：小于3秒</span></div>
        </div>
      </div>
      <div class="text-wrapper_207 flex-col">
        <span class="text_455">数据协议：内嵌SL651协议，支持定制协议传输</span>
      </div>
      <div class="group_7 flex-col">
        <div class="text-wrapper_208 flex-row"><span class="text_31">研发背景</span></div>
        <div class="group_157 flex-row">
          <div class="box_186 flex-col justify-between">
            <div class="text-wrapper_12 flex-col"><span class="text_32">PART&nbsp;01</span></div>
            <div class="text-wrapper_13 flex-col"><span class="text_33">PART&nbsp;02</span></div>
            <div class="text-wrapper_14 flex-col"><span class="text_34">PART&nbsp;03</span></div>
            <div class="text-wrapper_15 flex-col"><span class="text_35">PART&nbsp;04</span></div>
          </div>
          <span class="paragraph_7">
            上门抄表，费时费力，成本高，效率低
            <br />
            更换要断水，工作量大
            <br />
            信号差异，数据回传率不能保证
            <br />
            有些环境不具备更换水表条件
            <br />
          </span>
          <div class="box_187 flex-col">
            <div class="text-wrapper_16 flex-col"><span class="text_36">PART&nbsp;05</span></div>
            <div class="text-wrapper_17 flex-col"><span class="text_37">PART&nbsp;06</span></div>
            <div class="text-wrapper_18 flex-col"><span class="text_38">PART&nbsp;07</span></div>
            <div class="text-wrapper_19 flex-col"><span class="text_39">PART&nbsp;08</span></div>
            <div class="text-wrapper_20 flex-col"><span class="text_40">PART&nbsp;09</span></div>
          </div>
          <span class="paragraph_8">
            轻量化人工智能算法研发成功
            <br />
            边缘侧CPU算力满足要求
            <br />
            低功耗控制芯片，整机待机功耗可低到300nA
            <br />
            NB-Iot网络的覆盖，功耗低，资费低
            <br />
            大容量，低自放电池技术成熟
          </span>
        </div>
      </div>
      <div class="group_158 flex-col">
        <div class="group_159 flex-row">
          <img
            class="image_226"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
          />
          <div class="block_78 flex-col">
            <div class="image-wrapper_222 flex-row justify-between">
              <img
                class="image_227"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
              />
              <img
                class="image_228"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
              />
            </div>
            <img
              class="thumbnail_54"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
            />
            <img
              class="image_229"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
            />
          </div>
          <div class="text-wrapper_209 flex-col justify-between">
            <span class="text_456">周一至周五&nbsp;8：00-18:00</span>
            <span class="paragraph_65">
              北京总代理：北京迈瑞成科技有限公司
              <br />
              电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
              <br />
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
            </span>
          </div>
        </div>
        <div class="group_160 flex-row">
          <div class="box_188 flex-col justify-between">
            <img
              class="image_230"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
            />
            <span class="text_457">北京市海淀区翠微路甲3号</span>
          </div>
          <div class="text-group_121 flex-col justify-between">
            <span class="text_458">关键技术&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_459">下载中心&nbsp;&nbsp;&nbsp;|</span>
          </div>
          <div class="text-group_122 flex-col justify-between">
            <span class="text_460">产品中心&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_461">关于我们</span>
          </div>
          <span class="text_462">&nbsp;&nbsp;应用案例</span>
          <div class="box_189 flex-row">
            <div class="text-wrapper_210">
              <span class="text_463">售后服务：</span>
              <span class="paragraph_66">
                010-57751726
                <br />
              </span>
              <span class="text_464">商务合作：</span>
              <span class="text_465">010-83683458</span>
            </div>
            <span class="text_466">7*24小时售后客服热线</span>
          </div>
        </div>
      </div>
      <div class="group_161 flex-col">
        <div class="group_162 flex-row">
          <div class="image-wrapper_223 flex-col">
            <img
              class="label_128"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng9696777172e1a56a3c547afd85d229fa7601132482f9253065fa01401483e22c.png"
            />
          </div>
          <div class="box_190 flex-col">
            <div class="box_191 flex-col">
              <div class="group_163 flex-row justify-between">
                <div class="image-wrapper_224 flex-col">
                  <img
                    class="label_129"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng12ea35ea90bcf2444edbcbd1088e4ea34ef407a75582e7eeceaab00c06337a8c.png"
                  />
                </div>
                <img
                  class="image_231"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngdd7e272b0cad87c48a677a12f3f5cfeec9124086e91f7bf6e0b18a7d7da85d8b.png"
                />
              </div>
              <div class="image-text_51 flex-row justify-between">
                <img
                  class="label_130"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng71ed66ffcec124e64e3724095ab4eb0a29af2f61014a45d2a202185ece8efc32.png"
                />
                <span class="text-group_123">物联网</span>
              </div>
              <div class="group_164 flex-row">
                <img
                  class="image_232"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng9956d0f44e36d8398b10f06597836a30f5ce921a76e6a8c6dd00f32a2b8103b2.png"
                />
                <img
                  class="image_233"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngc791116d51a18863f8af875b97bb905e78f1d4ff7a0c9bd294e2135c9f272d72.png"
                />
                <div class="image-wrapper_225 flex-col justify-between">
                  <img
                    class="label_131"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng27af4d8d2a97e342d3f7beb027fb317c9044abe50d0a964daa2211d8be7389e2.png"
                  />
                  <img
                    class="image_234"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng3e202d1a323a231fa7c7a31546df58717f3a4e9bb2ce6f28afd233b487e510fc.png"
                  />
                </div>
              </div>
              <div class="group_165 flex-row">
                <div class="text-group_124 flex-col justify-between">
                  <span class="text_467">边缘人工智能</span>
                  <span class="text_468">【边缘AI】</span>
                </div>
              </div>
            </div>
          </div>
          <div class="image-wrapper_226 flex-col">
            <img
              class="label_132"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngdbdac5459e25c7dbfeff73772dc8cdf18dde44a3c288bd8c037b59dded58ef24.png"
            />
          </div>
        </div>
        <div class="group_166 flex-row justify-between">
          <div class="image-wrapper_227 flex-col">
            <img
              class="label_133"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngdab362f5d7ee4a8388023625196133c9c84d3af2124204a0b18dc5650bd399ea.png"
            />
          </div>
          <div class="image-wrapper_228 flex-col">
            <img
              class="label_134"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng6ba224d5737fa2e0bce48752c1bf20e194600a58e79b57d65aa91ddaadef7ef3.png"
            />
          </div>
        </div>
      </div>
      <div class="image-wrapper_229 flex-col">
        <img
          class="label_135"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng6c282cb0e295ba66d492ede2147604bfac11dbb38768e7c2be1d13adc1675e14.png"
        />
      </div>
      <div class="image-wrapper_230 flex-col">
        <img
          class="label_136"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng93d38e593e8a312d3588291202831ffb079c2bbd8917eafd80d760cecd90bf0d.png"
        />
      </div>
      <div class="image-wrapper_231 flex-col">
        <img
          class="label_137"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5d3fba06c012f9578b2fc187ed95c7e95a79aca65bf94763e0154166242228d3.png"
        />
      </div>
      <div class="image-wrapper_232 flex-col">
        <img
          class="label_138"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng519489b32e43fa8053b9c727b623b84cea9bc09b4339381ba2836e3eb6792957.png"
        />
      </div>
      <div class="image-wrapper_233 flex-col">
        <img
          class="label_139"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng137d889f1860937a5414953d8597fc9048aa8cfa419b810d57d0c5ec5afce2e5.png"
        />
      </div>
      <div class="text-wrapper_211 flex-col"><span class="text_469">一机一码，全生命周期维护</span></div>
      <div class="text-wrapper_212 flex-col"><span class="text_470">内外置双天线，数据畅通率99%以上</span></div>
      <div class="text-wrapper_213 flex-col"><span class="text_471">模型大小：300K</span></div>
      <div class="text-wrapper_214 flex-col"><span class="text_472">待机功耗：300nA(0.3uA)</span></div>
    </div>
    <div class="group_167 flex-row">
      <div class="image-text_52 flex-row justify-between">
        <img
          class="thumbnail_55"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
        />
        <a target="_blank" class="text-group_125" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802041940">京公网安备
              11010802041940号</a>
        <!-- <span class="text-group_125">京公网安备&nbsp;11010802041940号</span> -->
      </div>
      <div class="image-text_53 flex-row justify-between">
        <img
          class="thumbnail_56"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
        />
        <a target="_blank" class="text-group_126" href="https://beian.miit.gov.cn/">2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1</a>
        <!-- <span class="text-group_126">
          2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
        </span> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    goToUrl(url) {
      window.location.href = url;
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />