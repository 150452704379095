<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <img
        class="image_57"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
      />
      <span class="text_1">产品中心</span>
      <span class="text_32">PRODUCT&nbsp;CENTER</span>
      <div class="block_24 flex-col">
        <div class="section_66 flex-row">
          <img
            class="image_58"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
          />
          <!-- <span class="text_122">首页</span>
          <span class="text_123">关键技术</span>
          <span class="text_124">产品中心</span>
          <span class="text_125">应用案例</span>
          <span class="text_126">下载中心</span>
          <span class="text_127">关于我们</span> -->

          <span class="text_122"><router-link :to="{ path: '/lanhu_shouyebeifen' }">首页</router-link></span>
          <span class="text_123"><router-link :to="{ path: '/lanhu_guanjianjishu' }">关键技术</router-link></span>
          <span class="text_124"><router-link :to="{ path: '/lanhu_chanpinzhongxin' }">产品中心</router-link></span>
          <span class="text_125"><router-link :to="{ path: '/lanhu_yingyonganli' }">应用案例</router-link></span>
          <span class="text_126"><router-link :to="{ path: '/lanhu_xiazaizhongxin' }">下载中心</router-link></span>
          <span class="text_127"><router-link :to="{ path: '/lanhu_guanyuwomen' }">关于我们</router-link></span>

          <img
            class="label_6"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
          />
          <span class="text_141">400-123-8888</span>
          <img
            class="label_7"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
          />
          <span class="text_142" @click="goToUrl('https://www.xzeai.com:6090')">运维管理平台</span>
        </div>
        <div class="image-wrapper_49 flex-row">
          <img
            class="image_59"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
          />
        </div>
      </div>
    </div>
    <div class="box_131 flex-col">
      <span class="text_12">
        基于物联网、人工智能、边缘计算技术研制，在机械水表上拍照识别，采集水量数据，通过物联网技术实现数据的可靠传输。采用云、边、端抄表器协同开发，针对不同的应用场景，分别推出了云端AI抄表器、边缘AI抄表器，矩阵式AI抄表器三个系列产品。
      </span>
      <div class="group_146 flex-row justify-between">
        <div class="group_147 flex-col justify-between">
          <div class="group_23 flex-row">
            <div class="text-group_152 flex-col justify-between">
              <span class="text_45">产品分类</span>
              <span class="text_46">product</span>
            </div>
          </div>
          <div class="block_9 flex-row">
            <div class="image-text_72 flex-row justify-between">
              <img
                class="image_20"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng308a072d035623ba842e09a514d1f12723864e3b8f97b194571482838ee7d7bd.png"
              />
              <span class="text_48">水表</span>
            </div>
          </div>
          <div class="text-wrapper_11 flex-col"><span class="text_49">边缘AI抄表器</span></div>
          <div class="text-wrapper_12 flex-col"><span class="text_50">矩阵式AI抄表器</span></div>
          <div class="text-wrapper_13 flex-col"><span class="text_51">云端AI抄表器</span></div>
          <div class="group_50 flex-row">
            <div class="image-text_73 flex-row justify-between">
              <img
                class="thumbnail_9"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng308a072d035623ba842e09a514d1f12723864e3b8f97b194571482838ee7d7bd.png"
              />
              <span class="text-group_31">压力表</span>
            </div>
          </div>
          <div class="text-wrapper_15 flex-col"><span class="text_53">边缘AI抄表器</span></div>
          <div class="group_51 flex-row">
            <div class="image-text_74 flex-row justify-between">
              <img
                class="thumbnail_10"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng916a7367eff4999486f5ff46d8b6af7efea02caf09ea4d8c0ecb57ed7a0db727.png"
              />
              <span class="text-group_32">煤气表</span>
            </div>
          </div>
        </div>
        <div class="group_148 flex-col">
          <div class="text-wrapper_10 flex-col"><span class="text_47">水表</span></div>
          <div class="group_25 flex-row">
            <div class="image-wrapper_2 flex-col">
              <img
                class="image_3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngaee930944d168f77457b6935f52ea1f61e97bab8b23a2de4ec19dfda5b544cf5.png"
              />
            </div>
            <div class="group_149 flex-col justify-between">
              <div class="text-group_153 flex-col justify-between">
                <span class="text_13">边缘AI抄表器</span>
                <span class="text_14">
                  边缘AI抄表器可便捷安装在各种类型的水表上，在前端实现图像识别和数据存储，利用NB-Iot网络将结果发送到制定的大数据应用平台。适用于居民、非居民常规管径（DN15-DN200）的机械水表的水量数据采集与监控，可安装于井下、泵房、野外等各类环境。
                </span>
              </div>
              <div class="group_150 flex-row justify-between">
                <div class="text-wrapper_1 flex-col"><span class="text_15">立即咨询+</span></div>
                <div class="text-wrapper_2 flex-col"><span class="text_16">查看详情+</span></div>
              </div>
            </div>
          </div>
          <div class="group_26 flex-row">
            <div class="image-wrapper_11 flex-col">
              <img
                class="image_4"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng70afa0ff7b707d94af05245cb0278afa89edef244d6bc68f31eb85aa31929581.png"
              />
            </div>
            <div class="group_151 flex-col justify-between">
              <div class="text-group_154 flex-col justify-between">
                <span class="text_54">云端AI抄表器</span>
                <span class="text_55">
                  云AI抄表器采用了边缘侧图像采集，云端识别的工作模式，适用于居民、非居民各类水表自动抄表场景，尤其适用于对成本敏感的居民户水表的自动抄表领域。
                </span>
              </div>
              <div class="box_132 flex-row justify-between">
                <div class="text-wrapper_16 flex-col"><span class="text_56">立即咨询+</span></div>
                <div class="text-wrapper_17 flex-col"><span class="text_57">查看详情+</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="group_28 flex-row">
        <div class="image-wrapper_12 flex-row">
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngea0baaa8e8d1be48ca9b75f2c353d5b31f457e9e484abb31f38c8bff71b2e2f4.png"
          />
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngc3212f26dca7b3f7bb705907f3814a6f9857b426232d54873b854f8df66353f8.png"
          />
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngaf04c209d9ee9481d64a7429f9c36d4ab7f070e30e7dd6ccea4a19fa39807cd5.png"
          />
        </div>
        <div class="group_152 flex-col justify-between">
          <div class="text-group_155 flex-col justify-between">
            <span class="text_58">矩阵式AI抄表器</span>
            <span class="text_59">
              矩阵式AI抄表器由分布采集器和AI识别网关组成，适用于水表集中的场合，分布式采集器采集水表照片，AI识别网关进行智能识别和数据传输，具有低成本、低功耗、低资费等特点。
            </span>
          </div>
          <div class="block_72 flex-row justify-between">
            <div class="text-wrapper_18 flex-col"><span class="text_60">立即咨询+</span></div>
            <div class="text-wrapper_19 flex-col"><span class="text_61">查看详情+</span></div>
          </div>
        </div>
      </div>
      <div class="group_30 flex-row">
        <div class="image-wrapper_13 flex-col">
          <img
            class="image_26"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngd8456e7e1cb38e842f5200de629612e1a677dc8eca2491f85d327fbce4ee4b2b.png"
          />
        </div>
        <div class="section_67 flex-col justify-between">
          <div class="text-group_156 flex-col justify-between">
            <span class="text_62">压力表边缘AI抄表器</span>
            <span class="text_63">
              利用边缘人工智能和物联网技术，针对压力表智能抄表领域研制的产品，可便捷安装在指针式压力表上，在前端实现数据的识别和传输，满足多种类型压力表的自动抄表需求。
            </span>
          </div>
          <div class="box_133 flex-row justify-between">
            <div class="text-wrapper_20 flex-col"><span class="text_64">立即咨询+</span></div>
            <div class="text-wrapper_21 flex-col"><span class="text_65">查看详情+</span></div>
          </div>
        </div>
      </div>
      <div class="group_153 flex-col">
        <div class="section_68 flex-row">
          <img
            class="image_129"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
          />
          <div class="box_134 flex-col">
            <div class="image-wrapper_50 flex-row justify-between">
              <img
                class="image_130"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
              />
              <img
                class="image_131"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
              />
            </div>
            <img
              class="thumbnail_44"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
            />
            <img
              class="image_132"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
            />
          </div>
          <div class="text-wrapper_63 flex-col justify-between">
            <span class="text_264">周一至周五&nbsp;8：00-18:00</span>
            <span class="paragraph_49">
              北京总代理：北京迈瑞成科技有限公司
              <br />
              电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
              <br />
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
            </span>
          </div>
        </div>
        <div class="section_69 flex-row">
          <div class="box_135 flex-col justify-between">
            <img
              class="image_133"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
            />
            <span class="text_265">北京市海淀区翠微路甲3号</span>
          </div>
          <div class="text-group_157 flex-col justify-between">
            <span class="text_266">关键技术&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_267">下载中心&nbsp;&nbsp;&nbsp;|</span>
          </div>
          <div class="text-group_158 flex-col justify-between">
            <span class="text_268">产品中心&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_269">关于我们</span>
          </div>
          <span class="text_270">&nbsp;&nbsp;应用案例</span>
          <div class="box_136 flex-row">
            <div class="text-wrapper_64">
              <span class="text_271">售后服务：</span>
              <span class="paragraph_50">
                010-57751726
                <br />
              </span>
              <span class="text_272">商务合作：</span>
              <span class="text_273">010-83683458</span>
            </div>
            <span class="text_274">7*24小时售后客服热线</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box_137 flex-row">
      <div class="image-text_75 flex-row justify-between">
        <!-- <img
          class="thumbnail_45"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
        />
        <span class="text-group_159">京公网安备&nbsp;11010802041940号</span> -->
        <img class="thumbnail_45" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png" />
        <a target="_blank" class="text-group_159" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802041940">京公网安备
              11010802041940号</a>
      </div>
      <div class="image-text_76 flex-row justify-between">
        <img
          class="thumbnail_46"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
        />
        <a target="_blank" class="text-group_160" href="https://beian.miit.gov.cn/">2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1</a>
        <!-- <span class="text-group_160">
          2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
        </span> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    goToUrl(url) {
      window.location.href = url;
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />