<template>
  <div class="page flex-col">
    <div class="section_138 flex-col">
      <div class="group_302 flex-col">
        <div class="section_193 flex-row">
          <img class="image_350" referrerpolicy="no-referrer"
            src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png" />
          <span class="text_922"><router-link :to="{ path: '/lanhu_shouyebeifen' }">首页</router-link></span>
          <span class="text_923"><router-link :to="{ path: '/lanhu_guanjianjishu' }">关键技术</router-link></span>
          <span class="text_924"><router-link :to="{ path: '/lanhu_chanpinzhongxin' }">产品中心</router-link></span>
          <span class="text_925"><router-link :to="{ path: '/lanhu_yingyonganli' }">应用案例</router-link></span>
          <span class="text_926"><router-link :to="{ path: '/lanhu_xiazaizhongxin' }">下载中心</router-link></span>
          <span class="text_927"><router-link :to="{ path: '/lanhu_guanyuwomen' }">关于我们</router-link></span>

          <!-- <span class="text_924">产品中心</span>
          <span class="text_925">应用案例</span>
          <span class="text_926">下载中心</span>
          <span class="text_927">关于我们</span> -->
          <img class="label_183" referrerpolicy="no-referrer"
            src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png" />
          <span class="text_928">400-123-8888</span>
          <img class="label_184" referrerpolicy="no-referrer"
            src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png" />
          <span class="text_929" @click="goToUrl('https://www.xzeai.com:6090')">运维管理平台</span>
        </div>
        <div class="image-wrapper_272 flex-row">
          <img class="image_351" referrerpolicy="no-referrer"
            src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png" />
        </div>
      </div>
      <span class="text_1">权威认证&nbsp;国家专利</span>
      <img class="image_1" referrerpolicy="no-referrer"
        src="./assets/img/SketchPng4eac589f075d7d97f6842bb551b3ae6b450564d7df817756418f0f3817c715d7.png" />
      <span class="text_2">我公司拥有多项国家专利及荣誉证书</span>
      <div class="group_303 flex-row justify-between">
        <div class="group_2 flex-col">
          <div class="image-wrapper_1 flex-col align-center">
            <img class="image_2" referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc6a2f8e5fba81c5fd9e5d236bd5072008ab5367bad1e4f25ad335ce442f83b07.png" />
          </div>
        </div>
        <img class="image_4" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng44cce03110501ebf152f6f71f8330450adf4beaf10ac45fe92b43505ecb64714.png" />
      </div>
      <div class="group_304 flex-row">
        <div class="box_2 flex-row justify-between">
          <img class="image_5" referrerpolicy="no-referrer"
            src="./assets/img/SketchPng1ca6c1f3dd0af5d223e94a531ec2115a773ae4e58d042ba759f52cdcad3dc402.png" />
          <span class="text_3">关于小智</span>
        </div>
        <div class="image-text_1 flex-row justify-between">
          <img class="label_1" referrerpolicy="no-referrer"
            src="./assets/img/SketchPngc7caf243a535927fb47c0a8e47d3199186fa43ca902219033ba586dc4ca625ec.png" />
          <span class="text-group_1">资质荣誉</span>
        </div>
        <div class="image-text_2 flex-row">
          <img class="image_7" referrerpolicy="no-referrer"
            src="./assets/img/SketchPng77e52c0e3f91e4a8476ed531fc35c693fda42c3c812fb61cb1599ff2de94810c.png" />
          <span class="text-group_2">边缘AI抄表器</span>
        </div>
        <div class="image-text_3 flex-row">
          <img class="image_110" referrerpolicy="no-referrer"
            src="./assets/img/SketchPng625d37ea9396213585cdfcf2c74212c8a62d9e32fee17120c889973b74b1d4f4.png" />
          <span class="text-group_3">矩阵式AI抄表器</span>
        </div>
      </div>
      <div class="image-wrapper_3 flex-col">
        <img class="image_10" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng56d57dd513d83ab76a0f8d1efb45997befd7b29b29a7a1336979bf10dc994ab7.png" />
      </div>
      <img class="image_11" referrerpolicy="no-referrer"
        src="./assets/img/SketchPng976db8ba7854708c74899b54e8e16576f126518b761add6cb046b23a7408197e.png" />
      <div class="image-wrapper_4 flex-col">
        <img class="image_12" referrerpolicy="no-referrer"
          src="./assets/img/SketchPngeee9d7d5e5a41e847913e08097ab6dc899897936352f4439a5101b9d8de8bdec.png" />
      </div>
      <img class="image_13" referrerpolicy="no-referrer"
        src="./assets/img/SketchPng5a7f7d3badcc8b53e5cd16b6ec725c880358bf1cbcf6cfd386926d32dd82e169.png" />
    </div>
    <div class="box_305 flex-col">
      <div class="text-group_570 flex-col justify-between">
        <span class="text_222">KEY&nbsp;TECHNOLOGY</span>
        <span class="text_223">关键技术</span>
      </div>
      <div class="section_194 flex-row">
        <div class="block_150 flex-col justify-between">
          <div class="text-wrapper_239 flex-col"><span class="text_930">轻量化视觉AI算法</span></div>
          <div class="text-wrapper_240 flex-col"><span class="text_931">识别准确率：大于99%</span></div>
        </div>
        <div class="block_151 flex-col"></div>
        <div class="block_152 flex-col justify-between">
          <div class="text-wrapper_241 flex-col"><span class="text_932">算力：0.2GOPS</span></div>
          <div class="text-wrapper_242 flex-col"><span class="text_933">算法运行时间：小于3秒</span></div>
        </div>
      </div>
      <div class="text-wrapper_243 flex-col">
        <span class="text_934">数据协议：内嵌SL651协议，支持定制协议传输</span>
      </div>
      <div class="group_117 flex-col">
        <div class="text-group_571 flex-col justify-between">
          <span class="text_236">PRODUCT&nbsp;CENTER</span>
          <span class="text_237">产品中心</span>
        </div>
        <span class="text_238">
          基于物联网、人工智能、边缘计算技术研制，在机械水表上拍照识别，采集水量数据，通过物联网技术实现数据的可靠传输。采用云、边、端抄表器协同开发，针对不同的应用场景，分别推出了云端AI抄表器、边缘AI抄表器，矩阵式AI抄表器三个系列产品。
        </span>
        <div class="block_153 flex-row justify-between">
          <div class="image-text_302 flex-col justify-between">
            <div class="image-wrapper_84 flex-col">
              <img class="image_111" referrerpolicy="no-referrer"
                src="./assets/img/SketchPng5a520853cee8c7f4a6a3c4b26d9fdc3b47e535593dd135925070a405c01f390d.png" />
            </div>
            <div class="text-group_572 flex-col justify-between">
              <span class="text_239">边缘AI抄表器</span>
              <span class="text_240">
                边缘AI抄表器可便捷安装在各种类型的水表上，在前端实现图像识别和数据存储，利用NB-Iot网络将结果发送到制定的大数据应用平台。
              </span>
            </div>
          </div>
          <div class="image-text_303 flex-col justify-between">
            <div class="image-wrapper_85 flex-col">
              <img class="image_112" referrerpolicy="no-referrer"
                src="./assets/img/SketchPngb998730f5e95a7316b68d5beaf87c13778f937c7d96a13a7099e0c4ca46e7351.png" />
            </div>
            <div class="text-group_573 flex-col justify-between">
              <span class="text_241">云端AI抄表器</span>
              <span class="text_242">
                云AI抄表器采用了边缘侧图像采集，云端识别的工作模式，适用于居民、非居民各类水表自动抄表场景，尤其适用于对成本敏感的居民户水表的自动抄表领域。
              </span>
            </div>
          </div>
          <div class="image-text_304 flex-col justify-between">
            <div class="image-wrapper_86 flex-row">
              <img class="image_113" referrerpolicy="no-referrer"
                src="./assets/img/SketchPng87452abf13b6d09a00edbbef4a077ac3c41d030d81a382cf913e1c8df55f8a49.png" />
              <img class="image_114" referrerpolicy="no-referrer"
                src="./assets/img/SketchPng5005853b8c313ea08c01ef77a2798af321ad9ae403b2f7c0f9c137ae0e537685.png" />
              <img class="image_115" referrerpolicy="no-referrer"
                src="./assets/img/SketchPngde09a022a5bea767cbbacedc99fe9ea6033cd4ff4cfceb53fca7cf7794e82c09.png" />
            </div>
            <div class="text-group_574 flex-col justify-between">
              <span class="text_243">矩阵式AI抄表器</span>
              <span class="text_244">
                矩阵式AI抄表器由分布采集器和AI识别网关组成，适用于水表集中的场合，分布式采集器采集水表照片，AI识别网关进行智能识别和数据传输，具有低成本、低功耗、低资费……
              </span>
            </div>
          </div>
        </div>
        <div class="block_154 flex-row justify-between">
          <div class="text-wrapper_58 flex-col"><span class="text_245">查看详情+</span></div>
          <div class="text-wrapper_59 flex-col"><span class="text_246">查看详情+</span></div>
          <div class="text-wrapper_60 flex-col"><span class="text_247">查看详情+</span></div>
        </div>
      </div>
      <div class="group_120 flex-col">
        <div class="box_121 flex-col">
          <div class="block_155 flex-row">
            <div class="text-group_575 flex-col justify-between">
              <span class="text_248">技术资料</span>
              <span class="text_249">可下载产品的规格书、串口调试助手、芯片资料、开发套件等</span>
            </div>
          </div>
          <div class="block_156 flex-row">
            <div class="block_157 flex-col">
              <span class="text_250">用户指导</span>
              <span class="text_251">1.边缘ai抄表器-用户手册</span>
              <span class="text_252">2.云端ai抄表器-用户手册</span>
              <span class="text_253">3.矩阵式ai抄表器-用户手册</span>
              <span class="text_254">4.鸿成鑫鼎产品选型手册</span>
              <div class="image-text_305 flex-row justify-between">
                <span class="text-group_244">查看更多</span>
                <img class="label_11" referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngc7df0683c4f502c93addbee9abf101cb10bad6df1f4a50fa64a358fcc57c2ace.png" />
              </div>
            </div>
            <div class="text-wrapper_244 flex-col justify-between">
              <span class="text_255">立即下载</span>
              <span class="text_256">立即下载</span>
              <span class="text_257">立即下载</span>
              <span class="text_258">立即下载</span>
            </div>
            <img class="image_116" referrerpolicy="no-referrer"
              src="./assets/img/SketchPng76d858a8cbc1a3ba1efcf38a573fc1dd737ba292073e72c532218aabc376aa5b.png" />
            <div class="block_158 flex-col">
              <span class="text_259">资料下载</span>
              <div class="text-group_576 flex-col justify-between">
                <span class="text_260">1.芯片资料</span>
                <span class="text_261">2.串口调试助手</span>
                <span class="text_262">3.开发套件</span>
              </div>
              <div class="image-text_306 flex-row justify-between">
                <span class="text-group_246">查看更多</span>
                <img class="label_12" referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngc7df0683c4f502c93addbee9abf101cb10bad6df1f4a50fa64a358fcc57c2ace.png" />
              </div>
            </div>
            <div class="text-group_577 flex-col justify-between">
              <span class="text_263">立即下载</span>
              <span class="text_264">立即下载</span>
              <span class="text_265">立即下载</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-group_578 flex-col justify-between">
        <span class="text_266">APPLICATION&nbsp;CASES</span>
        <span class="text_267">应用案例</span>
      </div>
      <div class="list_15 flex-row">
        <div class="list-items_1 flex-col" :style="{ background: item.lanhuBg0 }" v-for="(item, index) in loopData0"
          :key="index">
          <div class="text-wrapper_62 flex-col"><span class="text_268" v-html="item.lanhutext0"></span></div>
        </div>
      </div>
      <div class="text-wrapper_63">
        <span class="text_269">我们已为使用者和合作伙伴提供</span>
        <span class="text_270">28,367,980</span>
        <span class="text_271">台设备和咨询服务</span>
      </div>
      <div class="section_195 flex-col">
        <div class="text-group_579 flex-col justify-between">
          <span class="text_935">NEWS&nbsp;INFORMATIO</span>
          <span class="text_936">新闻资讯</span>
        </div>
        <div class="block_159 flex-row justify-between">
          <div class="group_305 flex-col">
            <div class="text-wrapper_245 flex-col">
              <span class="text_937">2023-4-14</span>
              <span class="text_938">2023企业业务合作伙伴大会召开，携手伙伴共启新征</span>
            </div>
          </div>
          <div class="group_306 flex-col">
            <span class="text_939">2023-4-12</span>
            <span class="text_940">2023企业业务合作伙伴大会召开，携手伙伴共启新征</span>
            <span class="text_941">2023企业业务合作伙伴大会召开，携手伙伴共启新征。2023企业业务合作伙伴大会召开…</span>
            <img class="image_352" referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc9cb7b2a84a62a89c75a34b7899815743614459e97ce22a908c44d71c30f0695.png" />
            <span class="text_942">2023-4-09</span>
            <span class="text_943">
              WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美…
            </span>
            <img class="image_353" referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc9cb7b2a84a62a89c75a34b7899815743614459e97ce22a908c44d71c30f0695.png" />
            <span class="text_944">2023-3-20</span>
            <span class="text_945">
              2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件2021年度全球十…
            </span>
            <img class="image_354" referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc9cb7b2a84a62a89c75a34b7899815743614459e97ce22a908c44d71c30f0695.png" />
            <div class="image-text_307 flex-row justify-between">
              <span class="text-group_580">查看更多</span>
              <img class="thumbnail_58" referrerpolicy="no-referrer"
                src="./assets/img/SketchPngb29ceeda61a09d1965813ed1731c1e7ad441f955211c3c70f767dd8b0b95a724.png" />
            </div>
            <span class="text_946">WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好</span>
            <span class="text_947">2021年度全球十大人工智能治理事件</span>
          </div>
        </div>
      </div>
      <div class="section_196 flex-col">
        <div class="box_306 flex-row">
          <img class="image_355" referrerpolicy="no-referrer"
            src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png" />
          <div class="box_307 flex-col">
            <div class="image-wrapper_273 flex-row justify-between">
              <img class="image_356" referrerpolicy="no-referrer"
                src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png" />
              <img class="image_357" referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png" />
            </div>
            <img class="thumbnail_59" referrerpolicy="no-referrer"
              src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png" />
            <img class="image_358" referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png" />
          </div>
          <div class="text-wrapper_246 flex-col justify-between">
            <span class="text_948">周一至周五&nbsp;8：00-18:00</span>
            <span class="paragraph_72">
              北京总代理：北京迈瑞成科技有限公司
              <br />
              电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
              <br />
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
            </span>
          </div>
        </div>
        <div class="box_308 flex-row">
          <div class="box_309 flex-col justify-between">
            <img class="image_359" referrerpolicy="no-referrer"
              src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png" />
            <span class="text_949">北京市海淀区翠微路甲3号</span>
          </div>
          <div class="text-group_581 flex-col justify-between">
            <span class="text_950">关键技术&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_951">下载中心&nbsp;&nbsp;&nbsp;|</span>
          </div>
          <div class="text-group_582 flex-col justify-between">
            <span class="text_952">产品中心&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_953">关于我们</span>
          </div>
          <span class="text_954">&nbsp;&nbsp;应用案例</span>
          <div class="box_310 flex-row">
            <div class="text-wrapper_247">
              <span class="text_955">售后服务：010-57751726</span>
              <!-- <span class="paragraph_73">
                010-57751726
                <br />
              </span> -->
              <br />
              <span class="text_956">商务合作：010-83683458</span>
              <!-- <span class="text_957"></span> -->
            </div>
            <span class="text_958">7*24小时售后客服热线</span>
          </div>
        </div>
      </div>
      <div class="section_197 flex-col">
        <div class="group_307 flex-row">
          <div class="image-wrapper_274 flex-col">
            <img class="label_185" referrerpolicy="no-referrer"
              src="./assets/img/SketchPng9696777172e1a56a3c547afd85d229fa7601132482f9253065fa01401483e22c.png" />
          </div>
          <div class="box_311 flex-col">
            <div class="section_198 flex-col">
              <div class="box_312 flex-row justify-between">
                <div class="image-wrapper_275 flex-col">
                  <img class="label_186" referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng12ea35ea90bcf2444edbcbd1088e4ea34ef407a75582e7eeceaab00c06337a8c.png" />
                </div>
                <img class="image_360" referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngdd7e272b0cad87c48a677a12f3f5cfeec9124086e91f7bf6e0b18a7d7da85d8b.png" />
              </div>
              <div class="image-text_308 flex-row justify-between">
                <img class="label_187" referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng71ed66ffcec124e64e3724095ab4eb0a29af2f61014a45d2a202185ece8efc32.png" />
                <span class="text-group_583">物联网</span>
              </div>
              <div class="box_313 flex-row">
                <img class="image_361" referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng9956d0f44e36d8398b10f06597836a30f5ce921a76e6a8c6dd00f32a2b8103b2.png" />
                <img class="image_362" referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngc791116d51a18863f8af875b97bb905e78f1d4ff7a0c9bd294e2135c9f272d72.png" />
                <div class="image-wrapper_276 flex-col justify-between">
                  <img class="label_188" referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng27af4d8d2a97e342d3f7beb027fb317c9044abe50d0a964daa2211d8be7389e2.png" />
                  <img class="image_363" referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng3e202d1a323a231fa7c7a31546df58717f3a4e9bb2ce6f28afd233b487e510fc.png" />
                </div>
              </div>
              <div class="box_314 flex-row">
                <div class="text-group_584 flex-col justify-between">
                  <span class="text_959">边缘人工智能</span>
                  <span class="text_960">【边缘AI】</span>
                </div>
              </div>
            </div>
          </div>
          <div class="image-wrapper_277 flex-col">
            <img class="label_189" referrerpolicy="no-referrer"
              src="./assets/img/SketchPngdbdac5459e25c7dbfeff73772dc8cdf18dde44a3c288bd8c037b59dded58ef24.png" />
          </div>
        </div>
        <div class="group_308 flex-row justify-between">
          <div class="image-wrapper_278 flex-col">
            <img class="label_190" referrerpolicy="no-referrer"
              src="./assets/img/SketchPngdab362f5d7ee4a8388023625196133c9c84d3af2124204a0b18dc5650bd399ea.png" />
          </div>
          <div class="image-wrapper_279 flex-col">
            <img class="label_191" referrerpolicy="no-referrer"
              src="./assets/img/SketchPng6ba224d5737fa2e0bce48752c1bf20e194600a58e79b57d65aa91ddaadef7ef3.png" />
          </div>
        </div>
      </div>
      <div class="image-wrapper_280 flex-col">
        <img class="label_192" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng6c282cb0e295ba66d492ede2147604bfac11dbb38768e7c2be1d13adc1675e14.png" />
      </div>
      <div class="image-wrapper_281 flex-col">
        <img class="label_193" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng93d38e593e8a312d3588291202831ffb079c2bbd8917eafd80d760cecd90bf0d.png" />
      </div>
      <div class="image-wrapper_282 flex-col">
        <img class="label_194" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5d3fba06c012f9578b2fc187ed95c7e95a79aca65bf94763e0154166242228d3.png" />
      </div>
      <div class="image-wrapper_283 flex-col">
        <img class="label_195" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng519489b32e43fa8053b9c727b623b84cea9bc09b4339381ba2836e3eb6792957.png" />
      </div>
      <div class="image-wrapper_284 flex-col">
        <img class="label_196" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng137d889f1860937a5414953d8597fc9048aa8cfa419b810d57d0c5ec5afce2e5.png" />
      </div>
      <div class="text-wrapper_248 flex-col"><span class="text_961">一机一码，全生命周期维护</span></div>
      <div class="text-wrapper_249 flex-col"><span class="text_962">内外置双天线，数据畅通率99%以上</span></div>
      <div class="text-wrapper_250 flex-col"><span class="text_963">模型大小：300K</span></div>
      <div class="text-wrapper_251 flex-col"><span class="text_964">待机功耗：300nA(0.3uA)</span></div>
    </div>
    <div class="box_315 flex-row">
      <div class="image-text_309 flex-row justify-between">
        <img class="thumbnail_60" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png" />
        <!-- <span class="text-group_585">京公网安备&nbsp;11010802041940</span> -->
        <a target="_blank" class="text-group_585" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802041940">京公网安备
              11010802041940号</a>
        <!-- <div style="width:300px;margin:0 auto; padding:20px 0;">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802041940"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src=""
              style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备
              11010802041940号</p>
          </a>
        </div> -->
      </div>
      <div class="image-text_310 flex-row justify-between">
        <!-- <img class="thumbnail_61" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png" />        -->
        <a target="_blank" class="text-group_586" href="https://beian.miit.gov.cn/">@2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1</a>
        <!-- <span class="text-group_586">
          2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
        </span> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/1aa4bd72a31f4382a863498838f73b70_mergeImage.png)',
          lanhutext0: '户外'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/2414036729854c2f9b6e41da1fc09ce5_mergeImage.png)',
          lanhutext0: '井下'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/c21fb7b02f61446bbecbfd0a95022139_mergeImage.png)',
          lanhutext0: '室内'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a3d5e4ce713442a68d1611e7ef65b1a1_mergeImage.png)',
          lanhutext0: '泵房'
        }
      ],
      constants: {}
    };
  },
  methods: {
    goToUrl(url) {
      window.location.href = url;
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />