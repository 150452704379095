<template>
  <div class="page flex-col">
    <div class="box_12 flex-col">
      <img
        class="image_15"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
      />
      <span class="text_1">下载中心</span>
      <span class="text_2">Download&nbsp;Center</span>
      <div class="box_13 flex-col">
        <div class="section_27 flex-row">
          <img
            class="image_16"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
          />
          <!-- <span class="text_77">首页</span>
          <span class="text_78">关键技术</span>
          <span class="text_79">产品中心</span>
          <span class="text_80">应用案例</span>
          <span class="text_81">下载中心</span>
          <span class="text_82">关于我们</span> -->

          <span class="text_77"><router-link :to="{ path: '/lanhu_shouyebeifen' }">首页</router-link></span>
          <span class="text_78"><router-link :to="{ path: '/lanhu_guanjianjishu' }">关键技术</router-link></span>
          <span class="text_79"><router-link :to="{ path: '/lanhu_chanpinzhongxin' }">产品中心</router-link></span>
          <span class="text_80"><router-link :to="{ path: '/lanhu_yingyonganli' }">应用案例</router-link></span>
          <span class="text_81"><router-link :to="{ path: '/lanhu_xiazaizhongxin' }">下载中心</router-link></span>
          <span class="text_82"><router-link :to="{ path: '/lanhu_guanyuwomen' }">关于我们</router-link></span>

          <img
            class="label_8"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
          />
          <span class="text_96">400-123-8888</span>
          <img
            class="label_9"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
          />
          <span class="text_97" @click="goToUrl('https://www.xzeai.com:6090')">运维管理平台</span>
        </div>
        <div class="image-wrapper_35 flex-row">
          <img
            class="image_17"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
          />
        </div>
      </div>
    </div>
    <div class="group_88 flex-col">
      <div class="text-group_77 flex-col justify-between">
        <span class="text_10">技术资料</span>
        <span class="text_11">
          可下载产品的规格书、串口调试助手、芯片资料、开发套件等
          <br />
          请登录运维平台进行下载：https://www.xzeai.com:6090/#/login
        </span>
      </div>
      <span class="text_12">用户指导</span>
      <div class="text-wrapper_1 flex-row">
        <span class="text_13">序号</span>
        <span class="text_14">标题</span>
        <span class="text_15">发布时间</span>
        <span class="text_16">操作</span>
      </div>
      <div class="text-wrapper_2 flex-row">
        <span class="text_17">01</span>
        <span class="text_18">边缘ai抄表器-用户手册</span>
        <span class="text_19">2023-04-15</span>
        <span class="text_20">立即下载</span>
      </div>
      <div class="block_37 flex-row justify-between">
        <div class="text-wrapper_3 flex-col"><span class="text_21">02</span></div>
        <div class="text-wrapper_4 flex-col"><span class="text_22">云端ai抄表器-用户手册</span></div>
        <div class="text-wrapper_5 flex-col"><span class="text_23">2023-03-20</span></div>
        <div class="text-wrapper_6 flex-col"><span class="text_24">立即下载</span></div>
      </div>
      <div class="block_38 flex-row justify-between">
        <div class="text-wrapper_7 flex-col"><span class="text_25">03</span></div>
        <div class="text-wrapper_8 flex-col"><span class="text_26">矩阵式ai抄表器-用户手册</span></div>
        <div class="text-wrapper_9 flex-col"><span class="text_27">2023-02-03</span></div>
        <div class="text-wrapper_10 flex-col"><span class="text_28">立即下载</span></div>
      </div>
      <div class="block_39 flex-row justify-between">
        <div class="text-wrapper_11 flex-col"><span class="text_29">04</span></div>
        <div class="text-wrapper_12 flex-col"><span class="text_30">鸿成鑫鼎产品选型手册</span></div>
        <div class="text-wrapper_13 flex-col"><span class="text_31">2023-01-28</span></div>
        <div class="text-wrapper_14 flex-col"><span class="text_32">立即下载</span></div>
      </div>
      <div class="image-text_61 flex-row justify-between">
        <span class="text-group_37">向下展开</span>
        <img
          class="thumbnail_19"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngc0ab4c57151accc0de8def792905049054cd3313c877aef6bf17840798d3e01f.png"
        />
      </div>
      <span class="text_33">资料下载</span>
      <div class="text-wrapper_15 flex-row">
        <span class="text_34">序号</span>
        <span class="text_35">标题</span>
        <span class="text_36">发布时间</span>
        <span class="text_37">操作</span>
      </div>
      <div class="text-wrapper_16 flex-row">
        <span class="text_38">01</span>
        <span class="text_39">芯片研发应用</span>
        <span class="text_40">2023-03-18</span>
        <span class="text_41">立即下载</span>
      </div>
      <div class="block_40 flex-row justify-between">
        <div class="text-wrapper_17 flex-col"><span class="text_42">02</span></div>
        <div class="text-wrapper_18 flex-col"><span class="text_43">串口调试助手</span></div>
        <div class="text-wrapper_19 flex-col"><span class="text_44">2023-03-01</span></div>
        <div class="text-wrapper_20 flex-col"><span class="text_45">立即下载</span></div>
      </div>
      <div class="block_41 flex-row justify-between">
        <div class="text-wrapper_21 flex-col"><span class="text_46">03</span></div>
        <div class="text-wrapper_22 flex-col"><span class="text_47">运维平台操作手册</span></div>
        <div class="text-wrapper_23 flex-col"><span class="text_48">2023-02-15</span></div>
        <div class="text-wrapper_24 flex-col"><span class="text_49">立即下载</span></div>
      </div>
      <div class="block_42 flex-row justify-between">
        <div class="text-wrapper_25 flex-col"><span class="text_50">04</span></div>
        <div class="text-wrapper_26 flex-col"><span class="text_51">开发套件</span></div>
        <div class="text-wrapper_27 flex-col"><span class="text_52">2023-02-06</span></div>
        <div class="text-wrapper_28 flex-col"><span class="text_53">立即下载</span></div>
      </div>
      <div class="image-text_62 flex-row justify-between">
        <span class="text-group_3">向下展开</span>
        <img
          class="label_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngc0ab4c57151accc0de8def792905049054cd3313c877aef6bf17840798d3e01f.png"
        />
      </div>
      <div class="block_43 flex-col">
        <div class="group_89 flex-row">
          <img
            class="image_104"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
          />
          <div class="box_108 flex-col">
            <div class="image-wrapper_36 flex-row justify-between">
              <img
                class="image_105"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
              />
              <img
                class="image_106"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
              />
            </div>
            <img
              class="thumbnail_40"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
            />
            <img
              class="image_107"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
            />
          </div>
          <div class="text-wrapper_66 flex-col justify-between">
            <span class="text_252">周一至周五&nbsp;8：00-18:00</span>
            <span class="paragraph_39">
              北京总代理：北京迈瑞成科技有限公司
              <br />
              电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
              <br />
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
            </span>
          </div>
        </div>
        <div class="group_90 flex-row">
          <div class="box_109 flex-col justify-between">
            <img
              class="image_108"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
            />
            <span class="text_253">北京市海淀区翠微路甲3号</span>
          </div>
          <div class="text-group_78 flex-col justify-between">
            <span class="text_254">关键技术&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_255">下载中心&nbsp;&nbsp;&nbsp;|</span>
          </div>
          <div class="text-group_79 flex-col justify-between">
            <span class="text_256">产品中心&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_257">关于我们</span>
          </div>
          <span class="text_258">&nbsp;&nbsp;应用案例</span>
          <div class="box_110 flex-row">
            <div class="text-wrapper_67">
              <span class="text_259">售后服务：</span>
              <span class="paragraph_40">
                010-57751726
                <br />
              </span>
              <span class="text_260">商务合作：</span>
              <span class="text_261">010-83683458</span>
            </div>
            <span class="text_262">7*24小时售后客服热线</span>
          </div>
        </div>
      </div>
    </div>
    <div class="group_91 flex-row">
      <div class="image-text_63 flex-row justify-between">
        <!-- <img
          class="thumbnail_41"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
        />
        <span class="text-group_80">京公网安备&nbsp;11010802041940号</span> -->
        <img class="thumbnail_60" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png" />
        <a target="_blank" class="text-group_80" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802041940">京公网安备
              11010802041940号</a>
      </div>
      <div class="image-text_64 flex-row justify-between">
        <img
          class="thumbnail_42"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
        />
        <a target="_blank" class="text-group_81" href="https://beian.miit.gov.cn/">2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1</a>
        <!-- <span class="text-group_81">
          2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
        </span> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {
    goToUrl(url) {
      window.location.href = url;
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />