<template>
  <div class="page flex-col">
    <div class="block_30 flex-row">
      <div class="block_31 flex-col">
        <img
          class="image_107"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
        />
        <div class="block_32 flex-row justify-between">
          <div class="image-wrapper_1 flex-col">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng12a798d3c1c65198c5088499a2e16c3b431efeb0209324c7f45275b63b351fd5.png"
            />
          </div>
          <div class="group_75 flex-col justify-between">
            <span class="text_8">压力表边缘AI抄表器</span>
            <div class="image-text_56 flex-row justify-between">
              <div class="box_74 flex-col justify-between">
                <div class="group_4 flex-col"></div>
                <div class="group_5 flex-col"></div>
                <div class="group_6 flex-col"></div>
                <div class="group_7 flex-col"></div>
                <div class="group_8 flex-col"></div>
                <div class="group_9 flex-col"></div>
                <div class="group_10 flex-col"></div>
                <div class="group_11 flex-col"></div>
              </div>
              <span class="text-group_1">
                识别准确率：99%以上
                <br />
                低功耗设计：7年内无需更换电池
                <br />
                双天线设计：数据畅通率99%以上
                <br />
                工业摄像头：清晰、稳定
                <br />
                通讯模块：含10年通讯费
                <br />
                历史数据：可存储3个月
                <br />
                防护等级：IP68可长期浸泡在水中正常工作
                <br />
                工业级设计：-30～60℃稳定运行
              </span>
            </div>
          </div>
        </div>
        <div class="block_33 flex-row">
          <div class="image-wrapper_2 flex-col">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngee445526e6dda8de4d33df8f2ad1c70982ef9e9fd5c433747a22b894dc6d56bb.png"
            />
          </div>
          <div class="group_13 flex-col">
            <img
              class="image_14"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng4607adb1ec773c7d7d7c6fa3b31cab3dfb3c0427706c8a159f3ac5cad73694f3.png"
            />
          </div>
          <div class="group_14 flex-col">
            <img
              class="image_15"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng62f59286310db4b07aa161303ad51875948d7d46ced5abc88484e952b4eb2067.png"
            />
          </div>
          <div class="image-wrapper_4 flex-col">
            <img
              class="image_16"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng826def4c04a6bed7a0c381f192cf3c690ffeb73d40d8c1221a71917e6c714bdd.png"
            />
          </div>
          <span class="text_9">
            利用边缘人工智能和物联网技术，针对压力表智能抄表领域研制的产品，可便捷安装在指针式压力表上，在前端实现数据的识别和传输，满足多种类型压力表的自动抄表需求。
          </span>
        </div>
        <div class="block_34 flex-row justify-between">
          <div class="text-wrapper_24 flex-col"><span class="text_144">立即订购</span></div>
          <div class="text-wrapper_25 flex-col"><span class="text_145">资料下载</span></div>
        </div>
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng3d2d65076f31ce7ab05d198ab92825f1bc707c0a7cb3bc7c6d45e7ae125ac4e7.png"
        />
        <div class="group_16 flex-col">
          <span class="text_12">产品详情</span>
          <div class="image-text_57 flex-row">
            <div class="image-text_58 flex-col">
              <div class="group_76 flex-row">
                <img
                  class="image_5"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng19f5b711b1cfe1c680efb5e8c9b531bb9f43f9e22f76dc7472d20c1ec936cebd.png"
                />
                <span class="text_13">正视图</span>
                <span class="text_14">侧视图</span>
              </div>
              <span class="text_15">
                利用边缘人工智能和物联网技术，针对压力表智能抄表领域研制的产品，可便捷安装在指针式压力表上，在前端实现数据的识别和传输，满足多种类型压力表的自动抄表需求。
              </span>
            </div>
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng06e71e3bc6242a390bfd6fab8b0c8bed891b45313bb2f0fa229c990a8f0ce1a3.png"
            />
          </div>
        </div>
        <div class="block_35 flex-col">
          <div class="block_36 flex-row">
            <img
              class="image_108"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
            />
            <div class="section_39 flex-col">
              <div class="image-wrapper_17 flex-row justify-between">
                <img
                  class="image_109"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
                />
                <img
                  class="image_110"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
                />
              </div>
              <img
                class="thumbnail_29"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
              />
              <img
                class="image_111"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
              />
            </div>
            <div class="text-wrapper_36 flex-col justify-between">
              <span class="text_241">周一至周五&nbsp;8：00-18:00</span>
              <span class="paragraph_32">
                北京总代理：北京迈瑞成科技有限公司
                <br />
                电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
                <br />
                邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
              </span>
            </div>
          </div>
          <div class="block_37 flex-row">
            <div class="block_38 flex-col justify-between">
              <img
                class="image_112"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
              />
              <span class="text_242">北京市海淀区翠微路甲3号</span>
            </div>
            <div class="text-group_39 flex-col justify-between">
              <span class="text_243">关键技术&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_244">下载中心&nbsp;&nbsp;&nbsp;|</span>
            </div>
            <div class="text-group_40 flex-col justify-between">
              <span class="text_245">产品中心&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_246">关于我们</span>
            </div>
            <span class="text_247">&nbsp;&nbsp;应用案例</span>
            <div class="block_39 flex-row">
              <div class="text-wrapper_37">
                <span class="text_248">售后服务：</span>
                <span class="paragraph_33">
                  010-57751726
                  <br />
                </span>
                <span class="text_249">商务合作：</span>
                <span class="text_250">010-83683458</span>
              </div>
              <span class="text_251">7*24小时售后客服热线</span>
            </div>
          </div>
        </div>
        <img
          class="image_12"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng661a94bc57059c2817b95243206e76adc43b452aef5613fb125bb6b54725e3d3.png"
        />
      </div>
      <div class="block_40 flex-row">
        <div class="image-text_59 flex-row justify-between">
          <img
            class="thumbnail_30"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
          />
          <span class="text-group_41">京公网安备&nbsp;11010802041940号</span>
        </div>
        <div class="image-text_60 flex-row justify-between">
          <img
            class="thumbnail_31"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
          />
          <span class="text-group_42">
            2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
          </span>
        </div>
      </div>
    </div>
    <div class="block_41 flex-row">
      <img
        class="image_113"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
      />
      <span class="text_252">首页</span>
      <span class="text_253">关键技术</span>
      <span class="text_254">产品中心</span>
      <span class="text_255">应用案例</span>
      <span class="text_256">下载中心</span>
      <span class="text_257">关于我们</span>
      <img
        class="label_22"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
      />
      <span class="text_258">400-123-8888</span>
      <img
        class="label_23"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
      />
      <span class="text_259">水务运营平台</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />