<template>
  <div class="page flex-col">
    <div class="section_22 flex-row">
      <div class="group_63 flex-col">
        <img
          class="image_81"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
        />
        <div class="group_3 flex-col"></div>
        <div class="box_2 flex-col">
          <div class="section_23 flex-row justify-between">
            <div class="section_1 flex-row">
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng829a6d15ac2b47789c78a5964aab7f83ba76c073fe37d5d35f390b1d51cea3d5.png"
              />
              <span class="text_8">商务合作</span>
            </div>
            <div class="section_2 flex-row justify-between">
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng2ababd5c06925f0a40172638eda7067486c450df23b8a6982baf1de139bf0d73.png"
              />
              <span class="text_9">其他合作</span>
            </div>
          </div>
          <div class="section_24 flex-row">
            <div class="box_3 flex-row">
              <div class="text-group_84 flex-col justify-between">
                <span class="text_10">商务合作</span>
                <span class="text_11">13811330662&#64;163.com</span>
              </div>
            </div>
            <div class="box_4 flex-row">
              <div class="text-group_85 flex-col justify-between">
                <span class="text_12">供应商</span>
                <span class="text_13">13811330662&#64;163.com</span>
              </div>
            </div>
          </div>
          <div class="section_25 flex-row">
            <div class="group_4 flex-row">
              <div class="text-group_86 flex-col justify-between">
                <span class="text_14">投融资合作</span>
                <span class="text_15">13811330662&#64;163.com</span>
              </div>
            </div>
            <div class="group_5 flex-row">
              <div class="text-group_87 flex-col justify-between">
                <span class="text_16">问题反馈</span>
                <span class="text_17">13811330662&#64;163.com</span>
              </div>
            </div>
          </div>
        </div>
        <div class="group_64 flex-col">
          <div class="block_16 flex-row">
            <img
              class="image_82"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
            />
            <div class="group_65 flex-col">
              <div class="image-wrapper_14 flex-row justify-between">
                <img
                  class="image_83"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
                />
                <img
                  class="image_84"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
                />
              </div>
              <img
                class="thumbnail_26"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
              />
              <img
                class="image_85"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
              />
            </div>
            <div class="text-wrapper_25 flex-col justify-between">
              <span class="text_199">周一至周五&nbsp;8：00-18:00</span>
              <span class="paragraph_28">
                北京总代理：北京迈瑞成科技有限公司
                <br />
                电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
                <br />
                邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
              </span>
            </div>
          </div>
          <div class="block_17 flex-row">
            <div class="group_66 flex-col justify-between">
              <img
                class="image_86"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
              />
              <span class="text_200">北京市海淀区翠微路甲3号</span>
            </div>
            <div class="text-group_88 flex-col justify-between">
              <span class="text_201">关键技术&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_202">下载中心&nbsp;&nbsp;&nbsp;|</span>
            </div>
            <div class="text-group_89 flex-col justify-between">
              <span class="text_203">产品中心&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_204">关于我们</span>
            </div>
            <span class="text_205">&nbsp;&nbsp;应用案例</span>
            <div class="group_67 flex-row">
              <div class="text-wrapper_26">
                <span class="text_206">售后服务：</span>
                <span class="paragraph_29">
                  010-57751726
                  <br />
                </span>
                <span class="text_207">商务合作：</span>
                <span class="text_208">010-83683458</span>
              </div>
              <span class="text_209">7*24小时售后客服热线</span>
            </div>
          </div>
        </div>
      </div>
      <div class="group_68 flex-row">
        <div class="image-text_15 flex-row justify-between">
          <img
            class="thumbnail_27"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
          />
          <span class="text-group_90">京公网安备&nbsp;11010802041940号</span>
        </div>
        <div class="image-text_16 flex-row justify-between">
          <img
            class="thumbnail_28"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
          />
          <span class="text-group_91">
            2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
          </span>
        </div>
      </div>
    </div>
    <div class="section_26 flex-row">
      <img
        class="image_87"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
      />
      <span class="text_210">首页</span>
      <span class="text_211">关键技术</span>
      <span class="text_212">产品中心</span>
      <span class="text_213">应用案例</span>
      <span class="text_214">下载中心</span>
      <span class="text_215">关于我们</span>
      <img
        class="label_20"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
      />
      <span class="text_216">400-123-8888</span>
      <img
        class="label_21"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
      />
      <span class="text_217">水务运营平台</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />