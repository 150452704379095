<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="box_211 flex-col">
        <div class="box_212 flex-row">
          <img
            class="image_269"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
          />
          <span class="text_761">首页</span>
          <span class="text_762">关键技术</span>
          <span class="text_763">产品中心</span>
          <span class="text_764">应用案例</span>
          <span class="text_765">下载中心</span>
          <span class="text_766">关于我们</span>
          <img
            class="label_178"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
          />
          <span class="text_767">400-123-8888</span>
          <img
            class="label_179"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
          />
          <span class="text_768">水务运营平台</span>
        </div>
        <div class="image-wrapper_206 flex-row">
          <img
            class="image_270"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
          />
        </div>
      </div>
      <div class="box_213 flex-row justify-between">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngda802f5a638abe89c59e7dc22b854eb7371f153e5395c7bcad269ed6515fc3aa.png"
        />
        <div class="text-group_391 flex-col justify-between">
          <span class="text_8">边缘AI抄表器</span>
          <span class="text_9">
            边缘AI抄表器可便捷安装在各种类型的水表上，在前端实现图像识别和数据存储，利用NB-Iot网络将结果发送到制定的大数据应用平台。
          </span>
        </div>
      </div>
      <div class="box_214 flex-row">
        <div class="block_1 flex-row justify-between">
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng1ca6c1f3dd0af5d223e94a531ec2115a773ae4e58d042ba759f52cdcad3dc402.png"
          />
          <span class="text_10">关于小智</span>
        </div>
        <div class="image-text_1 flex-row justify-between">
          <img
            class="label_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngc7caf243a535927fb47c0a8e47d3199186fa43ca902219033ba586dc4ca625ec.png"
          />
          <span class="text-group_2">资质荣誉</span>
        </div>
        <div class="image-text_2 flex-row">
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng77e52c0e3f91e4a8476ed531fc35c693fda42c3c812fb61cb1599ff2de94810c.png"
          />
          <span class="text-group_3">边缘AI抄表器</span>
        </div>
        <div class="image-text_3 flex-row">
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng625d37ea9396213585cdfcf2c74212c8a62d9e32fee17120c889973b74b1d4f4.png"
          />
          <span class="text-group_4">矩阵式AI抄表器</span>
        </div>
      </div>
    </div>
    <div class="group_204 flex-col">
      <div class="text-group_392 flex-col justify-between">
        <span class="text_11">KEY&nbsp;TECHNOLOGY</span>
        <span class="text_12">关键技术</span>
      </div>
      <div class="section_106 flex-row">
        <div class="group_205 flex-col justify-between">
          <div class="text-wrapper_196 flex-col"><span class="text_769">轻量化视觉AI算法</span></div>
          <div class="text-wrapper_197 flex-col"><span class="text_770">识别准确率：大于99%</span></div>
        </div>
        <div class="group_206 flex-col"></div>
        <div class="group_207 flex-col justify-between">
          <div class="text-wrapper_198 flex-col"><span class="text_771">算力：0.2GOPS</span></div>
          <div class="text-wrapper_199 flex-col"><span class="text_772">算法运行时间：小于3秒</span></div>
        </div>
      </div>
      <div class="text-wrapper_200 flex-col">
        <span class="text_773">数据协议：内嵌SL651协议，支持定制协议传输</span>
      </div>
      <div class="group_21 flex-col">
        <div class="text-group_393 flex-col justify-between">
          <span class="text_78">PRODUCT&nbsp;CENTER</span>
          <span class="text_79">产品中心</span>
        </div>
        <span class="text_80">
          基于物联网、人工智能、边缘计算技术研制，在机械水表上拍照识别，采集水量数据，通过物联网技术实现数据的可靠传输。采用云、边、端抄表器协同开发，针对不同的应用场景，分别推出了云端AI抄表器、边缘AI抄表器，矩阵式AI抄表器三个系列产品。
        </span>
        <div class="group_208 flex-row justify-between">
          <div class="image-text_175 flex-col justify-between">
            <div class="image-wrapper_18 flex-col">
              <img
                class="image_31"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng5a520853cee8c7f4a6a3c4b26d9fdc3b47e535593dd135925070a405c01f390d.png"
              />
            </div>
            <div class="text-group_394 flex-col justify-between">
              <span class="text_81">边缘AI抄表器</span>
              <span class="text_82">
                边缘AI抄表器可便捷安装在各种类型的水表上，在前端实现图像识别和数据存储，利用NB-Iot网络将结果发送到制定的大数据应用平台。
              </span>
            </div>
          </div>
          <div class="image-text_176 flex-col justify-between">
            <div class="image-wrapper_19 flex-col">
              <img
                class="image_32"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngb998730f5e95a7316b68d5beaf87c13778f937c7d96a13a7099e0c4ca46e7351.png"
              />
            </div>
            <div class="text-group_395 flex-col justify-between">
              <span class="text_83">云端AI抄表器</span>
              <span class="text_84">
                云AI抄表器采用了边缘侧图像采集，云端识别的工作模式，适用于居民、非居民各类水表自动抄表场景，尤其适用于对成本敏感的居民户水表的自动抄表领域。
              </span>
            </div>
          </div>
          <div class="image-text_177 flex-col justify-between">
            <div class="image-wrapper_20 flex-row">
              <img
                class="image_33"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng87452abf13b6d09a00edbbef4a077ac3c41d030d81a382cf913e1c8df55f8a49.png"
              />
              <img
                class="image_34"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng5005853b8c313ea08c01ef77a2798af321ad9ae403b2f7c0f9c137ae0e537685.png"
              />
              <img
                class="image_35"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngde09a022a5bea767cbbacedc99fe9ea6033cd4ff4cfceb53fca7cf7794e82c09.png"
              />
            </div>
            <div class="text-group_396 flex-col justify-between">
              <span class="text_85">矩阵式AI抄表器</span>
              <span class="text_86">
                矩阵式AI抄表器由分布采集器和AI识别网关组成，适用于水表集中的场合，分布式采集器采集水表照片，AI识别网关进行智能识别和数据传输，具有低成本、低功耗、低资费……
              </span>
            </div>
          </div>
        </div>
        <div class="group_209 flex-row justify-between">
          <div class="text-wrapper_18 flex-col"><span class="text_87">查看详情+</span></div>
          <div class="text-wrapper_19 flex-col"><span class="text_88">查看详情+</span></div>
          <div class="text-wrapper_20 flex-col"><span class="text_89">查看详情+</span></div>
        </div>
      </div>
      <div class="group_22 flex-col">
        <div class="section_5 flex-col">
          <div class="block_107 flex-row">
            <div class="text-group_397 flex-col justify-between">
              <span class="text_90">技术资料</span>
              <span class="text_91">可下载产品的规格书、串口调试助手、芯片资料、开发套件等</span>
            </div>
          </div>
          <div class="block_108 flex-row">
            <div class="section_107 flex-col">
              <span class="text_92">用户指导</span>
              <span class="text_93">1.边缘ai抄表器-用户手册</span>
              <span class="text_94">2.云端ai抄表器-用户手册</span>
              <span class="text_95">3.矩阵式ai抄表器-用户手册</span>
              <span class="text_96">4.鸿成鑫鼎产品选型手册</span>
              <div class="image-text_178 flex-row justify-between">
                <span class="text-group_46">查看更多</span>
                <img
                  class="label_6"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngc7df0683c4f502c93addbee9abf101cb10bad6df1f4a50fa64a358fcc57c2ace.png"
                />
              </div>
            </div>
            <div class="text-wrapper_201 flex-col justify-between">
              <span class="text_97">立即下载</span>
              <span class="text_98">立即下载</span>
              <span class="text_99">立即下载</span>
              <span class="text_100">立即下载</span>
            </div>
            <img
              class="image_36"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng76d858a8cbc1a3ba1efcf38a573fc1dd737ba292073e72c532218aabc376aa5b.png"
            />
            <div class="section_108 flex-col">
              <span class="text_101">资料下载</span>
              <div class="text-group_398 flex-col justify-between">
                <span class="text_102">1.芯片资料</span>
                <span class="text_103">2.串口调试助手</span>
                <span class="text_104">3.开发套件</span>
              </div>
              <div class="image-text_179 flex-row justify-between">
                <span class="text-group_48">查看更多</span>
                <img
                  class="label_7"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngc7df0683c4f502c93addbee9abf101cb10bad6df1f4a50fa64a358fcc57c2ace.png"
                />
              </div>
            </div>
            <div class="text-group_399 flex-col justify-between">
              <span class="text_105">立即下载</span>
              <span class="text_106">立即下载</span>
              <span class="text_107">立即下载</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-group_400 flex-col justify-between">
        <span class="text_108">APPLICATION&nbsp;CASES</span>
        <span class="text_109">应用案例</span>
      </div>
      <div class="list_23 flex-row">
        <div
          class="list-items_2 flex-col"
          :style="{ background: item.lanhuBg0 }"
          v-for="(item, index) in loopData0"
          :key="index"
        >
          <div class="text-wrapper_22 flex-col"><span class="text_110" v-html="item.lanhutext0"></span></div>
        </div>
      </div>
      <div class="text-wrapper_23">
        <span class="text_111">我们已为使用者和合作伙伴提供</span>
        <span class="text_112">28,367,980</span>
        <span class="text_113">台设备和咨询服务</span>
      </div>
      <div class="section_109 flex-col">
        <div class="text-group_401 flex-col justify-between">
          <span class="text_774">NEWS&nbsp;INFORMATIO</span>
          <span class="text_775">新闻资讯</span>
        </div>
        <div class="block_109 flex-row justify-between">
          <div class="group_210 flex-col">
            <div class="text-wrapper_202 flex-col">
              <span class="text_776">2023-4-14</span>
              <span class="text_777">2023企业业务合作伙伴大会召开，携手伙伴共启新征</span>
            </div>
          </div>
          <div class="group_211 flex-col">
            <span class="text_778">2023-4-12</span>
            <span class="text_779">2023企业业务合作伙伴大会召开，携手伙伴共启新征</span>
            <span class="text_780">2023企业业务合作伙伴大会召开，携手伙伴共启新征。2023企业业务合作伙伴大会召开…</span>
            <img
              class="image_271"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc9cb7b2a84a62a89c75a34b7899815743614459e97ce22a908c44d71c30f0695.png"
            />
            <span class="text_781">2023-4-09</span>
            <span class="text_782">
              WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美…
            </span>
            <img
              class="image_272"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc9cb7b2a84a62a89c75a34b7899815743614459e97ce22a908c44d71c30f0695.png"
            />
            <span class="text_783">2023-3-20</span>
            <span class="text_784">
              2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件2021年度全球十…
            </span>
            <img
              class="image_273"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc9cb7b2a84a62a89c75a34b7899815743614459e97ce22a908c44d71c30f0695.png"
            />
            <div class="image-text_180 flex-row justify-between">
              <span class="text-group_402">查看更多</span>
              <img
                class="thumbnail_53"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngb29ceeda61a09d1965813ed1731c1e7ad441f955211c3c70f767dd8b0b95a724.png"
              />
            </div>
            <span class="text_785">WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好</span>
            <span class="text_786">2021年度全球十大人工智能治理事件</span>
          </div>
        </div>
      </div>
      <div class="section_110 flex-col">
        <div class="box_215 flex-row">
          <img
            class="image_274"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
          />
          <div class="box_216 flex-col">
            <div class="image-wrapper_207 flex-row justify-between">
              <img
                class="image_275"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
              />
              <img
                class="image_276"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
              />
            </div>
            <img
              class="thumbnail_54"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
            />
            <img
              class="image_277"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
            />
          </div>
          <div class="text-wrapper_203 flex-col justify-between">
            <span class="text_787">周一至周五&nbsp;8：00-18:00</span>
            <span class="paragraph_51">
              北京总代理：北京迈瑞成科技有限公司
              <br />
              电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
              <br />
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
            </span>
          </div>
        </div>
        <div class="box_217 flex-row">
          <div class="block_110 flex-col justify-between">
            <img
              class="image_278"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
            />
            <span class="text_788">北京市海淀区翠微路甲3号</span>
          </div>
          <div class="text-group_403 flex-col justify-between">
            <span class="text_789">关键技术&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_790">下载中心&nbsp;&nbsp;&nbsp;|</span>
          </div>
          <div class="text-group_404 flex-col justify-between">
            <span class="text_791">产品中心&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_792">关于我们</span>
          </div>
          <span class="text_793">&nbsp;&nbsp;应用案例</span>
          <div class="block_111 flex-row">
            <div class="text-wrapper_204">
              <span class="text_794">售后服务：</span>
              <span class="paragraph_52">
                010-57751726
                <br />
              </span>
              <span class="text_795">商务合作：</span>
              <span class="text_796">010-83683458</span>
            </div>
            <span class="text_797">7*24小时售后客服热线</span>
          </div>
        </div>
      </div>
      <div class="section_111 flex-col">
        <div class="block_112 flex-row">
          <div class="image-wrapper_208 flex-col">
            <img
              class="label_180"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng9696777172e1a56a3c547afd85d229fa7601132482f9253065fa01401483e22c.png"
            />
          </div>
          <div class="group_212 flex-col">
            <div class="section_112 flex-col">
              <div class="section_113 flex-row justify-between">
                <div class="image-wrapper_209 flex-col">
                  <img
                    class="label_181"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng12ea35ea90bcf2444edbcbd1088e4ea34ef407a75582e7eeceaab00c06337a8c.png"
                  />
                </div>
                <img
                  class="image_279"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngdd7e272b0cad87c48a677a12f3f5cfeec9124086e91f7bf6e0b18a7d7da85d8b.png"
                />
              </div>
              <div class="image-text_181 flex-row justify-between">
                <img
                  class="label_182"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng71ed66ffcec124e64e3724095ab4eb0a29af2f61014a45d2a202185ece8efc32.png"
                />
                <span class="text-group_405">物联网</span>
              </div>
              <div class="section_114 flex-row">
                <img
                  class="image_280"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng9956d0f44e36d8398b10f06597836a30f5ce921a76e6a8c6dd00f32a2b8103b2.png"
                />
                <img
                  class="image_281"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngc791116d51a18863f8af875b97bb905e78f1d4ff7a0c9bd294e2135c9f272d72.png"
                />
                <div class="image-wrapper_210 flex-col justify-between">
                  <img
                    class="label_183"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng27af4d8d2a97e342d3f7beb027fb317c9044abe50d0a964daa2211d8be7389e2.png"
                  />
                  <img
                    class="image_282"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng3e202d1a323a231fa7c7a31546df58717f3a4e9bb2ce6f28afd233b487e510fc.png"
                  />
                </div>
              </div>
              <div class="section_115 flex-row">
                <div class="text-group_406 flex-col justify-between">
                  <span class="text_798">边缘人工智能</span>
                  <span class="text_799">【边缘AI】</span>
                </div>
              </div>
            </div>
          </div>
          <div class="image-wrapper_211 flex-col">
            <img
              class="label_184"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngdbdac5459e25c7dbfeff73772dc8cdf18dde44a3c288bd8c037b59dded58ef24.png"
            />
          </div>
        </div>
        <div class="block_113 flex-row justify-between">
          <div class="image-wrapper_212 flex-col">
            <img
              class="label_185"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngdab362f5d7ee4a8388023625196133c9c84d3af2124204a0b18dc5650bd399ea.png"
            />
          </div>
          <div class="image-wrapper_213 flex-col">
            <img
              class="label_186"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng6ba224d5737fa2e0bce48752c1bf20e194600a58e79b57d65aa91ddaadef7ef3.png"
            />
          </div>
        </div>
      </div>
      <div class="image-wrapper_214 flex-col">
        <img
          class="label_187"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng6c282cb0e295ba66d492ede2147604bfac11dbb38768e7c2be1d13adc1675e14.png"
        />
      </div>
      <div class="image-wrapper_215 flex-col">
        <img
          class="label_188"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng93d38e593e8a312d3588291202831ffb079c2bbd8917eafd80d760cecd90bf0d.png"
        />
      </div>
      <div class="image-wrapper_216 flex-col">
        <img
          class="label_189"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng5d3fba06c012f9578b2fc187ed95c7e95a79aca65bf94763e0154166242228d3.png"
        />
      </div>
      <div class="image-wrapper_217 flex-col">
        <img
          class="label_190"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng519489b32e43fa8053b9c727b623b84cea9bc09b4339381ba2836e3eb6792957.png"
        />
      </div>
      <div class="image-wrapper_218 flex-col">
        <img
          class="label_191"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng137d889f1860937a5414953d8597fc9048aa8cfa419b810d57d0c5ec5afce2e5.png"
        />
      </div>
      <div class="text-wrapper_205 flex-col"><span class="text_800">一机一码，全生命周期维护</span></div>
      <div class="text-wrapper_206 flex-col"><span class="text_801">内外置双天线，数据畅通率99%以上</span></div>
      <div class="text-wrapper_207 flex-col"><span class="text_802">模型大小：300K</span></div>
      <div class="text-wrapper_208 flex-col"><span class="text_803">待机功耗：300nA(0.3uA)</span></div>
    </div>
    <div class="group_213 flex-row">
      <div class="image-text_182 flex-row justify-between">
        <!-- <img
          class="thumbnail_55"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
        />
        <span class="text-group_407">京公网安备&nbsp;11010802041940号</span> -->
      </div>
      <div class="image-text_183 flex-row justify-between">
        <img
          class="thumbnail_56"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
        />
        <span class="text-group_408">
          2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/abdfc656b8f04e7d894d175f8c1ed3c2_mergeImage.png)',
          lanhutext0: '户外'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/25a05d3c1aba4ee483c8456d1701852d_mergeImage.png)',
          lanhutext0: '井下'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/bc4b9aff3c7b494da1e0d91a9295df96_mergeImage.png)',
          lanhutext0: '室内'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/baf78d067be54889989a3481bce6977a_mergeImage.png)',
          lanhutext0: '泵房'
        }
      ],
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />