<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="group_2 flex-col">
        <img
          class="image_80"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
        />
        <span class="text_1">新闻资讯</span>
        <span class="text_2">News&nbsp;information</span>
        <div class="section_20 flex-row">
          <img
            class="image_81"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
          />
          <span class="text_213">首页</span>
          <span class="text_214">关键技术</span>
          <span class="text_215">产品中心</span>
          <span class="text_216">应用案例</span>
          <span class="text_217">下载中心</span>
          <span class="text_218">关于我们</span>
          <img
            class="label_21"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
          />
          <span class="text_219">400-123-8888</span>
          <img
            class="label_22"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
          />
          <span class="text_220">水务运营平台</span>
        </div>
      </div>
    </div>
    <div class="box_45 flex-col">
      <div class="text-wrapper_1">
        <span class="text_11">首页&nbsp;/&nbsp;新闻资讯&nbsp;/</span>
        <span class="text_12">&nbsp;新闻动态</span>
      </div>
      <div class="section_21 flex-row justify-between">
        <div class="group_54 flex-col">
          <div class="text-group_61 flex-col justify-between">
            <span class="text_13">2023企业业务合作伙伴大会召开，携手伙伴共启新征</span>
            <span class="text_14">2023-04-14</span>
          </div>
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng21efa3ce93a03e3f5505af08c75cb22da421c12195d55ac064d96b785fe993c5.png"
          />
          <div class="text-group_62 flex-col">
            <span class="text_15">
              3月10日，“视界无疆&nbsp;共启新征”2023企业业务合作伙伴大会在北京召开。近200位来自不同行业、不同区域的合作伙伴代表齐聚一堂，在万物复苏欣欣向荣的春天一起开启新征程。会上，分享了空间数字化落地的最新实践，发布全线焕新的硬件产品，介绍不断升级的产业生态能力。
            </span>
            <span class="text_16">做影响物理世界的AI技术创新</span>
            <span class="paragraph_1">
              当前，AI正在迎来新一轮的技术发展浪潮。联合创始人、CEO认为，AI未来会沿着两个大的方向演进：一是“AI&nbsp;in&nbsp;Digital”，以ChatGPT为代表的技术，将给数字世界带来新技术范式的迁移。二是“AI&nbsp;in&nbsp;Physical”，以特斯拉为代表的企业，将AI技术引擎与硬件载体结合，产生自动驾驶、机器人等不同类型的智能机器，对物理世界进行改造。印奇表示，目标是要做影响物理世界的AI技术创新。
              <br />
              <br />
              过去十多年，最自信的就是建立了一套强大的技术体系，在不断引领并推动AI技术的变革演进，并成长为中国AI技术创新的领军力量之一。研究院建立了由500多名AI研究员构成的研发团队，能输出从底层深度学习框架，到算法研发、软硬一体化产品的完整技术能力。这一研发体系的规模，足以媲美许多全球巨头企业。如果在AI核心技术上无法引领，中国的AI企业，就会在全球竞争中逐渐失去自己的位置。因此，非常坚定地在核心技术研发方面持续投入。
            </span>
          </div>
        </div>
        <div class="group_55 flex-col justify-between">
          <span class="text_17">热门新闻</span>
          <div class="text-wrapper_2 flex-col">
            <span class="text_18">2023企业业务合作伙伴大会召开，携手伙伴…</span>
            <span class="text_19">WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好</span>
            <span class="text_20">2021年度全球十大人工智能治理事件</span>
            <span class="text_21">2023企业业务合作伙伴大会召开，携手伙伴…</span>
            <span class="text_22">WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好</span>
            <span class="text_23">2021年度全球十大人工智能治理事件</span>
            <span class="text_24">WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好</span>
            <span class="text_25">2021年度全球十大人工智能治理事件</span>
          </div>
        </div>
      </div>
      <img
        class="image_4"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng02d33948bf57da633f80c589373bb8e489788eabe65a2a5687ff32336ca0e06c.png"
      />
      <span class="text_26">
        面向未来，企业业务会用持续不断的好产品，与好伙伴们紧密团结起来，一起把大场景做深，把小场景做细，帮助客户和社会创造更多的增量价值，贡献经济复苏的AI活力。
      </span>
      <div class="section_22 flex-row justify-between">
        <div class="text-wrapper_3 flex-row">
          <span class="text_27">上一篇：</span>
          <span class="text_28">暂无</span>
        </div>
        <div class="text-wrapper_4 flex-row">
          <span class="text_29">下一篇：</span>
          <span class="text_30">2021年度全球十大…</span>
        </div>
      </div>
      <div class="section_23 flex-col">
        <div class="section_24 flex-row">
          <img
            class="image_82"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
          />
          <div class="block_17 flex-col">
            <div class="image-wrapper_11 flex-row justify-between">
              <img
                class="image_83"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
              />
              <img
                class="image_84"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
              />
            </div>
            <img
              class="thumbnail_24"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
            />
            <img
              class="image_85"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
            />
          </div>
          <div class="text-wrapper_25 flex-col justify-between">
            <span class="text_221">周一至周五&nbsp;8：00-18:00</span>
            <span class="paragraph_22">
              北京总代理：北京迈瑞成科技有限公司
              <br />
              电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
              <br />
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
            </span>
          </div>
        </div>
        <div class="section_25 flex-row">
          <div class="section_26 flex-col justify-between">
            <img
              class="image_86"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
            />
            <span class="text_222">北京市海淀区翠微路甲3号</span>
          </div>
          <div class="text-group_63 flex-col justify-between">
            <span class="text_223">关键技术&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_224">下载中心&nbsp;&nbsp;&nbsp;|</span>
          </div>
          <div class="text-group_64 flex-col justify-between">
            <span class="text_225">产品中心&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_226">关于我们</span>
          </div>
          <span class="text_227">&nbsp;&nbsp;应用案例</span>
          <div class="section_27 flex-row">
            <div class="text-wrapper_26">
              <span class="text_228">售后服务：</span>
              <span class="paragraph_23">
                010-57751726
                <br />
              </span>
              <span class="text_229">商务合作：</span>
              <span class="text_230">010-83683458</span>
            </div>
            <span class="text_231">7*24小时售后客服热线</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box_46 flex-row">
      <div class="image-text_21 flex-row justify-between">
        <!-- <img
          class="thumbnail_25"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
        /> -->
        <!-- <span class="text-group_65">京公网安备&nbsp;11010802041940号</span> -->
      </div>
      <div class="image-text_22 flex-row justify-between">
        <img
          class="thumbnail_26"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
        />
        <span class="text-group_66">
          2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />