<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="box_1 flex-col">
        <img
          class="image_30"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
        />
        <span class="text_8">关于我们</span>
        <span class="text_9">About&nbsp;us</span>
        <div class="section_9 flex-col">
          <div class="section_51 flex-row">
            <img
              class="image_31"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
            />
            <!-- <span class="text_41">首页</span>
            <span class="text_42">关键技术</span>
            <span class="text_43">产品中心</span>
            <span class="text_44">应用案例</span>
            <span class="text_45">下载中心</span>
            <span class="text_46">关于我们</span> -->

            <span class="text_41"><router-link :to="{ path: '/lanhu_shouyebeifen' }">首页</router-link></span>
          <span class="text_42"><router-link :to="{ path: '/lanhu_guanjianjishu' }">关键技术</router-link></span>
          <span class="text_43"><router-link :to="{ path: '/lanhu_chanpinzhongxin' }">产品中心</router-link></span>
          <span class="text_44"><router-link :to="{ path: '/lanhu_yingyonganli' }">应用案例</router-link></span>
          <span class="text_45"><router-link :to="{ path: '/lanhu_xiazaizhongxin' }">下载中心</router-link></span>
          <span class="text_46"><router-link :to="{ path: '/lanhu_guanyuwomen' }">关于我们</router-link></span>
            <img
              class="label_9"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
            />
            <span class="text_60">400-123-8888</span>
            <img
              class="label_10"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
            />
            <span class="text_61" @click="goToUrl('https://www.xzeai.com:6090')">运维管理平台</span>
          </div>
          <div class="image-wrapper_82 flex-row">
            <img
              class="image_32"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="group_94 flex-col">
      <div class="section_52 flex-row justify-between">
        <div class="block_3 flex-col"></div>
        <div class="group_95 flex-col">
          <span class="text_10">公司简介</span>
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng715f01eafe9a03d97ec7f74f51ed1a2ee6f69062a750c4140bf2fca2f8fd754c.png"
          />
          <span class="text_11">
            北京鸿成鑫鼎智能科技有限责任公司从2012年成立之初开始，一直致力于物联网，人工智能、大数据方向的研发和应用，形成一套现场数据采集，边缘AI计算，物联网传输、大数据应用的完整应用体系和成熟的解决方案。
          </span>
        </div>
      </div>
      <div class="section_2 flex-col">
        <div class="section_53 flex-row">
          <div class="text-group_68 flex-col justify-between">
            <span class="text_12">荣誉资质</span>
            <span class="text_29">我司致力成为边缘AI与物联网领域的佼佼者</span>
          </div>
        </div>
        <div class="section_54 flex-row">
          <img
            class="image_51"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng36f12ca0415dd7512199ce7394d037c85a3d00fc278c89acee5e5915b58a4450.png"
          />
          <div class="image-wrapper_83 flex-row">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              :src="item.lanhuimage0"
              v-for="(item, index) in loopData0"
              :key="index"
            />
          </div>
          <img
            class="image_8"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngee82b936f30df8979af14dd2382411424ce75d0d3523427137b0e666928abf7b.png"
          />
          <img
            class="image_52"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngb6e4285f40e1acd8dc61ee2763f963b804b3281c081bb03266a8534bad44e22a.png"
          />
        </div>
      </div>
      <div class="section_55 flex-row justify-between">
        <div class="section_56 flex-col">
          <span class="text_13">联系我们</span>
          <div class="image-text_73 flex-row">
            <div class="image-text_74 flex-row">
              <div class="image-text_75 flex-row justify-between">
                <img
                  class="label_2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng6d6a0d5b1063ea7b80c18a6f63fb4a3b81f98606f6924effdc9fb9001b0bae81.png"
                />
                <span class="paragraph_1">
                  联系电话：13812345678
                  <br />
                  公司地址：北京市海淀区翠微路甲3号
                  <br />
                  公司邮箱：13812345678&#64;162.com
                </span>
              </div>
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngf0bcd2563dd27cd0fd4fa5e8f0bbc1e2cea63ce36721afcdb5086ba88f0b7409.png"
              />
            </div>
            <img
              class="label_4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd3619c67508c49a38bc6d4abd36d0baee151fd90a9536a5f75f39a34a6e69068.png"
            />
          </div>
          <img
            class="image_13"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngd12619ae9275f68ba435c9c5a2ca2a49f9ddf3be2367a57321c4b3f7bba22c48.png"
          />
          <div class="group_96 flex-row">
            <div class="group_97 flex-col justify-between">
              <img
                class="image_14"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
              />
              <span class="text_14">边缘AI抄表器小程序</span>
            </div>
            <div class="group_98 flex-col justify-between">
              <img
                class="image_15"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
              />
              <span class="text_15">app</span>
            </div>
            <span class="text_16">公众号</span>
          </div>
        </div>
        <img
          class="image_17"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngc9711a484ef8c6473cbe209aca48b2196102731631234d3b6d94c5c60475df10.png"
        />
      </div>
      <div class="section_57 flex-col">
        <div class="group_99 flex-row">
          <img
            class="image_117"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
          />
          <div class="section_58 flex-col">
            <div class="image-wrapper_84 flex-row justify-between">
              <img
                class="image_118"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
              />
              <img
                class="image_119"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
              />
            </div>
            <img
              class="thumbnail_36"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
            />
            <img
              class="image_120"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
            />
          </div>
          <div class="text-wrapper_65 flex-col justify-between">
            <span class="text_205">周一至周五&nbsp;8：00-18:00</span>
            <span class="paragraph_38">
              北京总代理：北京迈瑞成科技有限公司
              <br />
              电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
              <br />
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
            </span>
          </div>
        </div>
        <div class="group_100 flex-row">
          <div class="group_101 flex-col justify-between">
            <img
              class="image_121"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
            />
            <span class="text_206">北京市海淀区翠微路甲3号</span>
          </div>
          <div class="text-group_69 flex-col justify-between">
            <span class="text_207">关键技术&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_208">下载中心&nbsp;&nbsp;&nbsp;|</span>
          </div>
          <div class="text-group_70 flex-col justify-between">
            <span class="text_209">产品中心&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_210">关于我们</span>
          </div>
          <span class="text_211">&nbsp;&nbsp;应用案例</span>
          <div class="group_102 flex-row">
            <div class="text-wrapper_66">
              <span class="text_212">售后服务：</span>
              <span class="paragraph_39">
                010-57751726
                <br />
              </span>
              <span class="text_213">商务合作：</span>
              <span class="text_214">010-83683458</span>
            </div>
            <span class="text_215">7*24小时售后客服热线</span>
          </div>
        </div>
      </div>
      <div class="section_5 flex-col"></div>
      <img
        class="image_88"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng62a6a18e9c025847d510c16d4a09e19f8481e3d68deb9a846acbb5cd8b8c8a55.png"
      />
    </div>
    <div class="group_103 flex-row">
      <div class="image-text_76 flex-row justify-between">
        <!-- <img
          class="thumbnail_37"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
        /> -->
        <img class="thumbnail_60" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png" />
        <a target="_blank" class="text-group_71" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802041940">京公网安备
              11010802041940号</a>
        <!-- <span class="text-group_71">京公网安备&nbsp;11010802041940号</span> -->
      </div>
      <div class="image-text_77 flex-row justify-between">
        <img
          class="thumbnail_38"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
        />
        <span class="text-group_72">
          2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng27e94905b938875c94092bde5691f42ea3bb3d076cb43001c8fcdfb7323c1219'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngbef5994932de7fcd0fb946d1880201413a7a4c74fbea16840043e27add6d085f'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga2e2bff9e5c145185b95729533e4e2e62ddc0858c9bb34347c3fa6d19e4d73db'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd36e1e15fea0a65ef0689cec3b2c95f24f31301adb533b16fb5ad9ffee017473'
        }
      ],
      constants: {}
    };
  },
  methods: {
    goToUrl(url) {
      window.location.href = url;
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />