<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="block_2 flex-col">
        <img
          class="image_74"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
        />
        <span class="text_1">新闻资讯</span>
        <span class="text_2">News&nbsp;information</span>
        <div class="box_46 flex-row">
          <img
            class="image_75"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
          />
          <span class="text_176">首页</span>
          <span class="text_177">关键技术</span>
          <span class="text_178">产品中心</span>
          <span class="text_179">应用案例</span>
          <span class="text_180">下载中心</span>
          <span class="text_181">关于我们</span>
          <img
            class="label_17"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
          />
          <span class="text_182">400-123-8888</span>
          <img
            class="label_18"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
          />
          <span class="text_183">水务运营平台</span>
        </div>
      </div>
    </div>
    <div class="block_28 flex-col">
      <div class="box_47 flex-row justify-between">
        <div class="text-wrapper_1 flex-col"><span class="text_11">新闻动态</span></div>
        <div class="text-wrapper_2 flex-col"><span class="text_12">行业资讯</span></div>
      </div>
      <div class="group_3 flex-row">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng7d791f858548979b029ed3cd84c92f3c1fdfd292104b7de4ea6c86f049906c67.png"
        />
        <div class="group_52 flex-col">
          <div class="text-group_65 flex-col justify-between">
            <span class="text_13">2023-04-14</span>
            <span class="text_14">2023企业业务合作伙伴大会召开，携手伙伴共启新征</span>
          </div>
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng53be05d2a8bc1e5e581c4b331e291e85a83825ec1263d84439a0a64ceceac2f8.png"
          />
          <span class="text_15">
            2023企业业务合作伙伴大会召开，携手伙伴共启新征2023企业业务合作伙伴大会召开，携手伙伴共启新征2023企业业务合作伙伴大会召开，携手伙伴共启新征2023企业业务合作伙伴大会召开，携手伙伴共启新征2023企业业务合作伙伴大会召开，携手伙伴共启新征…
          </span>
        </div>
      </div>
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng48e9aa6d369609b29e9d9840e501e6972e84616e3e1abaacbc5804845a03fa3a.png"
      />
      <div class="box_48 flex-row justify-between">
        <div class="image-wrapper_1 flex-col">
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng563db17d52dfa4abdadf2fed83a735ca1682d1764c1bc3efdc188ff4824991f0.png"
          />
        </div>
        <div class="group_53 flex-col">
          <div class="text-group_66 flex-col justify-between">
            <span class="text_16">2023-04-12</span>
            <span class="text_17">2023企业业务合作伙伴大会召开，携手伙伴共启新征</span>
          </div>
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng53be05d2a8bc1e5e581c4b331e291e85a83825ec1263d84439a0a64ceceac2f8.png"
          />
          <span class="text_18">
            2023企业业务合作伙伴大会召开，携手伙伴共启新征2023企业业务合作伙伴大会召开，携手伙伴共启新征2023企业业务合作伙伴大会召开，携手伙伴共启新征2023企业业务合作伙伴大会召开，携手伙伴共启新征2023企业业务合作伙伴大会召开，携手伙伴共启新征…
          </span>
        </div>
      </div>
      <img
        class="image_8"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng48e9aa6d369609b29e9d9840e501e6972e84616e3e1abaacbc5804845a03fa3a.png"
      />
      <div class="box_49 flex-row justify-between">
        <div class="image-wrapper_2 flex-col">
          <img
            class="image_9"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng601a9bafc7b255771d2f200091bdbea50950680842a64617556374d5c3c28c13.png"
          />
        </div>
        <div class="group_54 flex-col">
          <div class="text-group_67 flex-col justify-between">
            <span class="text_19">2023-04-09</span>
            <span class="text_20">WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好</span>
          </div>
          <img
            class="image_10"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng53be05d2a8bc1e5e581c4b331e291e85a83825ec1263d84439a0a64ceceac2f8.png"
          />
          <span class="text_21">
            WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好WAIC&nbsp;2022&nbsp;|&nbsp;AI向实而生，让物理世界更美好WAIC&nbsp;2022&nbsp;|&nbsp;A…
          </span>
        </div>
      </div>
      <img
        class="image_11"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng48e9aa6d369609b29e9d9840e501e6972e84616e3e1abaacbc5804845a03fa3a.png"
      />
      <div class="box_50 flex-row justify-between">
        <div class="box_2 flex-col"></div>
        <div class="group_55 flex-col">
          <div class="text-group_68 flex-col justify-between">
            <span class="text_22">2023-03-20</span>
            <span class="text_23">2021年度全球十大人工智能治理事件</span>
          </div>
          <img
            class="image_12"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng53be05d2a8bc1e5e581c4b331e291e85a83825ec1263d84439a0a64ceceac2f8.png"
          />
          <span class="text_24">
            2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件2021年度全球十大人工智能治理事件…
          </span>
        </div>
      </div>
      <img
        class="image_13"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng48e9aa6d369609b29e9d9840e501e6972e84616e3e1abaacbc5804845a03fa3a.png"
      />
      <div class="box_51 flex-row justify-between">
        <div class="text-wrapper_3 flex-col"><span class="text_25">上一页</span></div>
        <div class="text-wrapper_4 flex-col"><span class="text_26">1</span></div>
        <div class="text-wrapper_5 flex-col"><span class="text_27">2</span></div>
        <div class="text-wrapper_6 flex-col"><span class="text_28">3</span></div>
        <div class="text-wrapper_7 flex-col"><span class="text_29">...</span></div>
        <div class="text-wrapper_8 flex-col"><span class="text_30">10</span></div>
        <div class="text-wrapper_9 flex-col"><span class="text_31">下一页</span></div>
      </div>
      <div class="box_52 flex-col">
        <div class="box_53 flex-row">
          <img
            class="image_76"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
          />
          <div class="box_54 flex-col">
            <div class="image-wrapper_11 flex-row justify-between">
              <img
                class="image_77"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
              />
              <img
                class="image_78"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
              />
            </div>
            <img
              class="thumbnail_19"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
            />
            <img
              class="image_79"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
            />
          </div>
          <div class="text-wrapper_26 flex-col justify-between">
            <span class="text_184">周一至周五&nbsp;8：00-18:00</span>
            <span class="paragraph_17">
              北京总代理：北京迈瑞成科技有限公司
              <br />
              电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
              <br />
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
            </span>
          </div>
        </div>
        <div class="box_55 flex-row">
          <div class="group_56 flex-col justify-between">
            <img
              class="image_80"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
            />
            <span class="text_185">北京市海淀区翠微路甲3号</span>
          </div>
          <div class="text-group_69 flex-col justify-between">
            <span class="text_186">关键技术&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_187">下载中心&nbsp;&nbsp;&nbsp;|</span>
          </div>
          <div class="text-group_70 flex-col justify-between">
            <span class="text_188">产品中心&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_189">关于我们</span>
          </div>
          <span class="text_190">&nbsp;&nbsp;应用案例</span>
          <div class="group_57 flex-row">
            <div class="text-wrapper_27">
              <span class="text_191">售后服务：</span>
              <span class="paragraph_18">
                010-57751726
                <br />
              </span>
              <span class="text_192">商务合作：</span>
              <span class="text_193">010-83683458</span>
            </div>
            <span class="text_194">7*24小时售后客服热线</span>
          </div>
        </div>
      </div>
    </div>
    <div class="block_29 flex-row">
      <div class="image-text_17 flex-row justify-between">
        <!-- <img
          class="thumbnail_20"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
        />
        <span class="text-group_71">京公网安备&nbsp;11010802041940号</span> -->
      </div>
      <div class="image-text_18 flex-row justify-between">
        <img
          class="thumbnail_21"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
        />
        <span class="text-group_72">
          2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />