<template>
  <div class="page flex-col">
    <div class="group_85 flex-row">
      <div class="group_86 flex-col">
        <img
          class="image_114"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
        />
        <div class="group_87 flex-row justify-between">
          <div class="image-wrapper_1 flex-col">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng5a520853cee8c7f4a6a3c4b26d9fdc3b47e535593dd135925070a405c01f390d.png"
            />
          </div>
          <div class="section_48 flex-col justify-between">
            <span class="text_8">边缘AI抄表器</span>
            <div class="image-text_87 flex-row">
              <div class="image-text_88 flex-row justify-between">
                <div class="block_31 flex-col justify-between">
                  <div class="box_4 flex-col"></div>
                  <div class="box_5 flex-col"></div>
                  <div class="box_6 flex-col"></div>
                  <div class="box_7 flex-col"></div>
                  <div class="box_10 flex-col"></div>
                  <div class="group_4 flex-col"></div>
                  <div class="box_9 flex-col"></div>
                  <div class="group_3 flex-col"></div>
                </div>
                <span class="paragraph_1">
                  工业摄像头：清晰、稳定
                  <br />
                  识别准确率：99%以上
                  <br />
                  低功耗设计：10年内外/井下/楼宇中稳定传输
                  <br />
                  通信模块：含10年通讯费
                  <br />
                  历史数据：可存储6年数据
                  <br />
                  防护等级：IP68可长期浸泡在水中正常工作
                  <br />
                  耐腐蚀性：可在滨海地区盐雾环境下稳定工作
                  <br />
                  工业级设计：-30～60℃稳定运行
                  <br />
                  遮光盖设计：方便人工检查水表
                </span>
              </div>
              <div class="box_8 flex-col"></div>
            </div>
          </div>
        </div>
        <div class="group_88 flex-row">
          <div class="image-wrapper_2 flex-col">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng09ca6a71e8692e5e96797b5314e3524929d16bdcf6d1e99b34c6d728a35e00a7.png"
            />
          </div>
          <div class="box_12 flex-col">
            <img
              class="image_13"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd65ef917e7c422980f78493f33588792837344ffc2557e906dded49af14bc0c3.png"
            />
          </div>
          <div class="box_13 flex-col">
            <img
              class="image_14"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd3474182460d0db20f06fe13ecfcec8790b5a7cf457bcd4a32c1c0ab55fa7e54.png"
            />
          </div>
          <img
            class="image_15"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng8a93e80b623ec8c8882a870a75c28dc93bf45c84a1c7a4ba8749d966ccd3ca8e.png"
          />
          <span class="text_9">
            &nbsp;可便捷安装在各种类型的水表上，在前端实现图像识别和数据存储，利用NB-Iot网络将结果发送到制定的大数据应用平台。适用于居民、非居民常规管径（DN15-DN200）的机械水表的水量数据采集与监控，可安装于井下、泵房、野外等各类环境。
          </span>
        </div>
        <div class="group_89 flex-row justify-between">
          <div class="text-wrapper_24 flex-col"><span class="text_152">资料下载</span></div>
          <div class="text-wrapper_25 flex-col"><span class="text_153">立即订购</span></div>
        </div>
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng3d2d65076f31ce7ab05d198ab92825f1bc707c0a7cb3bc7c6d45e7ae125ac4e7.png"
        />
        <div class="box_16 flex-col">
          <span class="text_12">产品详情</span>
          <span class="text_13">
            本产品是利用边缘人工智能和物联网技术，为满足水表自动抄表需求而研制的产品，可便捷安装在各种类型的水表上，在前端实现图像识别和数据存储，利用NB-Iot网络将结果发送到制定的大数据应用平台。
          </span>
          <div class="box_101 flex-row justify-between">
            <div class="image-text_89 flex-col justify-between">
              <img
                class="image_28"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng453848451da178a9ae790570cb2a272615fededd296109b48ed1615d9fecfb83.png"
              />
              <span class="text_21">正视图</span>
            </div>
            <div class="image-text_90 flex-col justify-between">
              <img
                class="image_29"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngf8890c915a2a4552f15bd5d115dcf1e9155ca13ee411045073666d519d6c087c.png"
              />
              <span class="text_22">侧视图</span>
            </div>
          </div>
        </div>
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngc9238cbc48ed3fc034d9488031376de838fecfbee941fd064d7d0b24f5d4deb9.png"
        />
        <div class="group_90 flex-col">
          <div class="group_91 flex-row">
            <img
              class="image_115"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
            />
            <div class="group_92 flex-col">
              <div class="image-wrapper_22 flex-row justify-between">
                <img
                  class="image_116"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
                />
                <img
                  class="image_117"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
                />
              </div>
              <img
                class="thumbnail_31"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
              />
              <img
                class="image_118"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
              />
            </div>
            <div class="text-wrapper_36 flex-col justify-between">
              <span class="text_249">周一至周五&nbsp;8：00-18:00</span>
              <span class="paragraph_37">
                北京总代理：北京迈瑞成科技有限公司
                <br />
                电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
                <br />
                邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
              </span>
            </div>
          </div>
          <div class="group_93 flex-row">
            <div class="block_32 flex-col justify-between">
              <img
                class="image_119"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
              />
              <span class="text_250">北京市海淀区翠微路甲3号</span>
            </div>
            <div class="text-group_45 flex-col justify-between">
              <span class="text_251">关键技术&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_252">下载中心&nbsp;&nbsp;&nbsp;|</span>
            </div>
            <div class="text-group_46 flex-col justify-between">
              <span class="text_253">产品中心&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_254">关于我们</span>
            </div>
            <span class="text_255">&nbsp;&nbsp;应用案例</span>
            <div class="block_33 flex-row">
              <div class="text-wrapper_37">
                <span class="text_256">售后服务：</span>
                <span class="paragraph_38">
                  010-57751726
                  <br />
                </span>
                <span class="text_257">商务合作：</span>
                <span class="text_258">010-83683458</span>
              </div>
              <span class="text_259">7*24小时售后客服热线</span>
            </div>
          </div>
        </div>
      </div>
      <div class="group_94 flex-row">
        <div class="image-text_91 flex-row justify-between">
          <img
            class="thumbnail_32"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
          />
          <span class="text-group_47">京公网安备&nbsp;11010802041940号</span>
        </div>
        <div class="image-text_92 flex-row justify-between">
          <img
            class="thumbnail_33"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
          />
          <span class="text-group_48">
            2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
          </span>
        </div>
      </div>
    </div>
    <div class="group_95 flex-row">
      <img
        class="image_120"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
      />
      <span class="text_260">首页</span>
      <span class="text_261">关键技术</span>
      <span class="text_262">产品中心</span>
      <span class="text_263">应用案例</span>
      <span class="text_264">下载中心</span>
      <span class="text_265">关于我们</span>
      <img
        class="label_22"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
      />
      <span class="text_266">400-123-8888</span>
      <img
        class="label_23"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
      />
      <span class="text_267">水务运营平台</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />