<template>
  <div class="page flex-col">
    <div class="group_8 flex-col">
      <img
        class="image_16"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
      />
      <span class="text_1">应用案例</span>
      <span class="text_2">Application&nbsp;Cases</span>
      <div class="box_5 flex-col">
        <div class="group_58 flex-row">
          <img
            class="image_17"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
          />
          <!-- <span class="text_45">首页</span>
          <span class="text_46">关键技术</span>
          <span class="text_47">产品中心</span>
          <span class="text_48">应用案例</span>
          <span class="text_49">下载中心</span>
          <span class="text_50">关于我们</span> -->

          <span class="text_45"><router-link :to="{ path: '/lanhu_shouyebeifen' }">首页</router-link></span>
          <span class="text_46"><router-link :to="{ path: '/lanhu_guanjianjishu' }">关键技术</router-link></span>
          <span class="text_47"><router-link :to="{ path: '/lanhu_chanpinzhongxin' }">产品中心</router-link></span>
          <span class="text_48"><router-link :to="{ path: '/lanhu_yingyonganli' }">应用案例</router-link></span>
          <span class="text_49"><router-link :to="{ path: '/lanhu_xiazaizhongxin' }">下载中心</router-link></span>
          <span class="text_50"><router-link :to="{ path: '/lanhu_guanyuwomen' }">关于我们</router-link></span>

          <img
            class="label_6"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
          />
          <span class="text_64">400-123-8888</span>
          <img
            class="label_7"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
          />
          <span class="text_65" @click="goToUrl('https://www.xzeai.com:6090')">运维管理平台</span>
        </div>
        <div class="image-wrapper_29 flex-row">
          <img
            class="image_18"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
          />
        </div>
      </div>
    </div>
    <div class="group_59 flex-col">
      <span class="text_10">案例精选</span>
      <div class="group_60 flex-row justify-between">
        <div class="section_1 flex-col">
          <div class="group_2 flex-row">
            <div class="text-group_87 flex-col justify-between">
              <span class="text_11">北京法政实业集团有限公司供水</span>
              <span class="text_12">深度合作，解决政法实业供水/回水问题</span>
            </div>
          </div>
        </div>
        <div class="group_61 flex-col justify-between">
          <div class="section_3 flex-row">
            <div class="text-group_88 flex-col justify-between">
              <span class="text_13">吉友大厦供水</span>
              <span class="text_14">深度合作，解决政法实业供水/回水问题</span>
            </div>
          </div>
          <div class="section_4 flex-row">
            <div class="text-group_89 flex-col justify-between">
              <span class="text_15">太子峪太南陶粒厂</span>
              <span class="text_16">深度合作，解决政法实业供水/回水问题</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block_4 flex-col">
        <span class="text_17">应用场景</span>
        <div class="grid_29 flex-row">
          <div
            class="list-items_1 flex-col"
            :style="{ background: item.lanhuBg0 }"
            v-for="(item, index) in loopData0"
            :key="index"
          >
            <div class="group_3 flex-col">
              <span class="text_18" v-html="item.lanhutext0"></span>
              <div v-if="item.slot1 === 1" class="group_4 flex-col"></div>
            </div>
          </div>
        </div>
      </div>
      <span class="text_19">部分案例展示</span>
      <div class="grid_30 flex-row">
        <div class="list-items_2 flex-col" v-for="(item, index) in loopData1" :key="index">
          <span class="text_20" v-html="item.lanhutext0"></span>
          <img class="image_3" referrerpolicy="no-referrer" :src="item.lanhuimage0" />
          <span class="text_21" v-html="item.lanhutext1"></span>
        </div>
      </div>
      <div class="group_62 flex-col">
        <div class="group_63 flex-row">
          <img
            class="image_88"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
          />
          <div class="group_64 flex-col">
            <div class="image-wrapper_30 flex-row justify-between">
              <img
                class="image_89"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
              />
              <img
                class="image_90"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
              />
            </div>
            <img
              class="thumbnail_32"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
            />
            <img
              class="image_91"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
            />
          </div>
          <div class="text-wrapper_32 flex-col justify-between">
            <span class="text_187">周一至周五&nbsp;8：00-18:00</span>
            <span class="paragraph_33">
              北京总代理：北京迈瑞成科技有限公司
              <br />
              电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
              <br />
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
            </span>
          </div>
        </div>
        <div class="group_65 flex-row">
          <div class="group_66 flex-col justify-between">
            <img
              class="image_92"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
            />
            <span class="text_188">北京市海淀区翠微路甲3号</span>
          </div>
          <div class="text-group_90 flex-col justify-between">
            <span class="text_189">关键技术&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_190">下载中心&nbsp;&nbsp;&nbsp;|</span>
          </div>
          <div class="text-group_91 flex-col justify-between">
            <span class="text_191">产品中心&nbsp;&nbsp;&nbsp;|</span>
            <span class="text_192">关于我们</span>
          </div>
          <span class="text_193">&nbsp;&nbsp;应用案例</span>
          <div class="group_67 flex-row">
            <div class="text-wrapper_33">
              <span class="text_194">售后服务：</span>
              <span class="paragraph_34">
                010-57751726
                <br />
              </span>
              <span class="text_195">商务合作：</span>
              <span class="text_196">010-83683458</span>
            </div>
            <span class="text_197">7*24小时售后客服热线</span>
          </div>
        </div>
      </div>
    </div>
    <div class="group_68 flex-row">
      <div class="image-text_21 flex-row justify-between">
        <!-- <img
          class="thumbnail_33"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
        />
        span class="text-group_92">京公网安备&nbsp;11010802041940号</span> -->
        <img class="thumbnail_60" referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png" />
        <a target="_blank" class="text-group_92" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802041940">京公网安备
              11010802041940号</a>
      </div>
      <div class="image-text_22 flex-row justify-between">
        <img
          class="thumbnail_34"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
        />
        <a target="_blank" class="text-group_93" href="https://beian.miit.gov.cn/">2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1</a>
        <!-- <span class="text-group_93">
          2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
        </span> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/61d81d029b9a42c2ad8190fce2d5d422_mergeImage.png)',
          lanhutext0: '办公楼',
          slot1: 1
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/316b1c0885ca43a587ce16949dcda7bb_mergeImage.png)',
          lanhutext0: '商场'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/6de652dd912d4c82a206d253b8ba7d5e_mergeImage.png)',
          lanhutext0: '酒店'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/6453c6ec34884012bebb02b6473d336b_mergeImage.png)',
          lanhutext0: '住宅小区'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/c3a109bbd8884dc284d0c8c0ee71fdd8_mergeImage.png)',
          lanhutext0: '学校'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/0e551966bdc047cfba916fd519553366_mergeImage.png)',
          lanhutext0: '医院'
        }
      ],
      loopData1: [
        {
          lanhutext0: '太子峪太南陶粒厂',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16fc4cdc83a9e6c132b1833a3164e6ec02d815d25d47210c0a420b3cfd98e24f',
          lanhutext1: '深度合作，解决供水/回水问题'
        },
        {
          lanhutext0: '丰台区和义农场',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16fc4cdc83a9e6c132b1833a3164e6ec02d815d25d47210c0a420b3cfd98e24f',
          lanhutext1: '深度合作，解决供水/回水问题'
        },
        {
          lanhutext0: '古北水镇',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16fc4cdc83a9e6c132b1833a3164e6ec02d815d25d47210c0a420b3cfd98e24f',
          lanhutext1: '深度合作，解决供水/回水问题'
        },
        {
          lanhutext0: '海淀区海兴大厦',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16fc4cdc83a9e6c132b1833a3164e6ec02d815d25d47210c0a420b3cfd98e24f',
          lanhutext1: '深度合作，解决供水/回水问题'
        },
        {
          lanhutext0: '海淀区水务工程部',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16fc4cdc83a9e6c132b1833a3164e6ec02d815d25d47210c0a420b3cfd98e24f',
          lanhutext1: '深度合作，解决供水/回水问题'
        },
        {
          lanhutext0: '西城区国家大剧院',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16fc4cdc83a9e6c132b1833a3164e6ec02d815d25d47210c0a420b3cfd98e24f',
          lanhutext1: '深度合作，解决供水/回水问题'
        },
        {
          lanhutext0: '东城区财贸职业学院',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16fc4cdc83a9e6c132b1833a3164e6ec02d815d25d47210c0a420b3cfd98e24f',
          lanhutext1: '深度合作，解决供水/回水问题'
        },
        {
          lanhutext0: '王府井购物中心',
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng16fc4cdc83a9e6c132b1833a3164e6ec02d815d25d47210c0a420b3cfd98e24f',
          lanhutext1: '深度合作，解决供水/回水问题'
        }
      ],
      constants: {}
    };
  },
  methods: {
    goToUrl(url) {
      window.location.href = url;
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />