<template>
  <div class="page flex-col">
    <div class="group_69 flex-row">
      <div class="box_58 flex-col">
        <img
          class="image_102"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
        />
        <div class="box_59 flex-row justify-between">
          <div class="image-wrapper_1 flex-col">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngf32aac11944b90f34dd25a4bc092d4e096f6fa84fd5585677781b256a311eee1.png"
            />
          </div>
          <div class="group_70 flex-col justify-between">
            <span class="text_8">云端AI抄表器</span>
            <div class="image-text_41 flex-row justify-between">
              <div class="block_30 flex-col justify-between">
                <div class="group_4 flex-col"></div>
                <div class="group_5 flex-col"></div>
                <div class="group_6 flex-col"></div>
                <div class="group_7 flex-col"></div>
                <div class="group_8 flex-col"></div>
                <div class="group_9 flex-col"></div>
                <div class="group_10 flex-col"></div>
                <div class="group_11 flex-col"></div>
              </div>
              <span class="text-group_1">
                工业摄像头：清晰、稳定
                <br />
                识别准确率：99%以上
                <br />
                低功耗设计：7年内无需更换电池
                <br />
                通信模块：含10年通讯费
                <br />
                历史数据：可存储3个月
                <br />
                防护等级：IP68可长期浸泡在水中正常工作
                <br />
                耐腐蚀性：可在滨海地区盐雾环境下稳定工作
                <br />
                工业级设计：-30～60℃稳定运行
              </span>
            </div>
          </div>
        </div>
        <div class="box_60 flex-row">
          <div class="image-wrapper_2 flex-col">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngdef22b84030fc19d549a65cef8abcab0f6e9cb6c9bd9039177e50116b5426b7b.png"
            />
          </div>
          <div class="image-wrapper_3 flex-col">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng22e4bbec70e1b47efc517f2df11a0d26b55a4acd4e412f2c3e3472093f4baf56.png"
            />
          </div>
          <div class="image-wrapper_4 flex-col">
            <img
              class="image_16"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng958003a542dd7590a2e827c5f46f8034f79d9e10c96f6249bb6ffaa3a7b96d57.png"
            />
          </div>
          <div class="image-wrapper_6 flex-col">
            <img
              class="image_17"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng743f30e368b90e0b157b87d4bfea53dfc9d149e19ce81af13a285175e0366690.png"
            />
          </div>
          <span class="text_9">
            云AI抄表器采用了边缘侧图像采集，云端识别的工作模式，适用于居民、非居民各类水表自动抄表场景，尤其适用于对成本敏感的居民户水表的自动抄表领域。
          </span>
        </div>
        <div class="box_61 flex-row justify-between">
          <div class="text-wrapper_21 flex-col"><span class="text_132">立即订购</span></div>
          <div class="text-wrapper_22 flex-col"><span class="text_133">资料下载</span></div>
        </div>
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng3d2d65076f31ce7ab05d198ab92825f1bc707c0a7cb3bc7c6d45e7ae125ac4e7.png"
        />
        <div class="group_14 flex-col">
          <span class="text_12">产品详情</span>
          <span class="text_13">
            云AI抄表器采用了边缘侧图像采集，云端识别的工作模式，适用于居民、非居民各类水表自动抄表场景，尤其适用于对成本敏感的居民户水表的自动抄表领域。
          </span>
          <div class="block_31 flex-row justify-between">
            <div class="section_57 flex-col justify-between">
              <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng1af16699e66c803479a6f5d771e4e2ae91093fd5e3954a06f2cd10ef0c78390f.png"
              />
              <span class="text_14">俯视图</span>
            </div>
            <div class="image-text_42 flex-col justify-between">
              <img
                class="image_8"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng3f99cf63e1d8c699ff23a987b7421ecac0c890fe0dec7edaa0a11ad07e319738.png"
              />
              <span class="text-group_2">侧视图</span>
            </div>
          </div>
        </div>
        <img
          class="image_9"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng86576fe1d90c86a88ee8794de295e23b34a34bbb30e4aaacdcf4925e2a9de999.png"
        />
        <div class="box_62 flex-col">
          <div class="box_63 flex-row">
            <img
              class="image_103"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
            />
            <div class="group_71 flex-col">
              <div class="image-wrapper_18 flex-row justify-between">
                <img
                  class="image_104"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
                />
                <img
                  class="image_105"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
                />
              </div>
              <img
                class="thumbnail_27"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
              />
              <img
                class="image_106"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
              />
            </div>
            <div class="text-wrapper_33 flex-col justify-between">
              <span class="text_229">周一至周五&nbsp;8：00-18:00</span>
              <span class="paragraph_29">
                北京总代理：北京迈瑞成科技有限公司
                <br />
                电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
                <br />
                邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
              </span>
            </div>
          </div>
          <div class="box_64 flex-row">
            <div class="box_65 flex-col justify-between">
              <img
                class="image_107"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
              />
              <span class="text_230">北京市海淀区翠微路甲3号</span>
            </div>
            <div class="text-group_39 flex-col justify-between">
              <span class="text_231">关键技术&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_232">下载中心&nbsp;&nbsp;&nbsp;|</span>
            </div>
            <div class="text-group_40 flex-col justify-between">
              <span class="text_233">产品中心&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_234">关于我们</span>
            </div>
            <span class="text_235">&nbsp;&nbsp;应用案例</span>
            <div class="box_66 flex-row">
              <div class="text-wrapper_34">
                <span class="text_236">售后服务：</span>
                <span class="paragraph_30">
                  010-57751726
                  <br />
                </span>
                <span class="text_237">商务合作：</span>
                <span class="text_238">010-83683458</span>
              </div>
              <span class="text_239">7*24小时售后客服热线</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box_67 flex-row">
        <div class="image-text_43 flex-row justify-between">
          <img
            class="thumbnail_28"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
          />
          <span class="text-group_41">京公网安备&nbsp;11010802041940号</span>
        </div>
        <div class="image-text_44 flex-row justify-between">
          <img
            class="thumbnail_29"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
          />
          <span class="text-group_42">
            2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
          </span>
        </div>
      </div>
    </div>
    <div class="group_72 flex-row">
      <img
        class="image_108"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
      />
      <span class="text_240">首页</span>
      <span class="text_241">关键技术</span>
      <span class="text_242">产品中心</span>
      <span class="text_243">应用案例</span>
      <span class="text_244">下载中心</span>
      <span class="text_245">关于我们</span>
      <img
        class="label_22"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
      />
      <span class="text_246">400-123-8888</span>
      <img
        class="label_23"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
      />
      <span class="text_247">水务运营平台</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />