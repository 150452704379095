import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_shouyebeifen from '../views/lanhu_shouyebeifen/index.vue'
import lanhu_chanpinzhongxinjuzhenshibianyuanaichaobiaoqi from '../views/lanhu_chanpinzhongxinjuzhenshibianyuanaichaobiaoqi/index.vue'
import lanhu_chanpinzhongxinyalibiaobianyuanaichaobiaoqi from '../views/lanhu_chanpinzhongxinyalibiaobianyuanaichaobiaoqi/index.vue'
import lanhu_xinwenzixun from '../views/lanhu_xinwenzixun/index.vue'
import lanhu_yingyonganli from '../views/lanhu_yingyonganli/index.vue'
import lanhu_chanpinzhongxin from '../views/lanhu_chanpinzhongxin/index.vue'
import lanhu_guanjianjishu from '../views/lanhu_guanjianjishu/index.vue'
import lanhu_chanpinzhongxinyunduanaichaobiaoqi from '../views/lanhu_chanpinzhongxinyunduanaichaobiaoqi/index.vue'
import lanhu_shouyebeifen3 from '../views/lanhu_shouyebeifen3/index.vue'
import lanhu_guanyuwomen from '../views/lanhu_guanyuwomen/index.vue'
import lanhu_xinwenzixun2 from '../views/lanhu_xinwenzixun2/index.vue'
import lanhu_xinwenzixunxiangqingye from '../views/lanhu_xinwenzixunxiangqingye/index.vue'
import lanhu_chanpinzhongxinbianyuanaichaobiaoqi from '../views/lanhu_chanpinzhongxinbianyuanaichaobiaoqi/index.vue'
import lanhu_lijizixunbeifen from '../views/lanhu_lijizixunbeifen/index.vue'
import lanhu_shouyebeifen2 from '../views/lanhu_shouyebeifen2/index.vue'
import lanhu_xiazaizhongxin from '../views/lanhu_xiazaizhongxin/index.vue'
import lanhu_shouye from '../views/lanhu_shouye/index.vue'
import lanhu_lijizixun from '../views/lanhu_lijizixun/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/lanhu_shouyebeifen"
  },
  {
    path: '/lanhu_shouyebeifen',
    name: 'lanhu_shouyebeifen',
    component: lanhu_shouyebeifen
  },
  {
    path: '/lanhu_chanpinzhongxinjuzhenshibianyuanaichaobiaoqi',
    name: 'lanhu_chanpinzhongxinjuzhenshibianyuanaichaobiaoqi',
    component: lanhu_chanpinzhongxinjuzhenshibianyuanaichaobiaoqi
  },
  {
    path: '/lanhu_chanpinzhongxinyalibiaobianyuanaichaobiaoqi',
    name: 'lanhu_chanpinzhongxinyalibiaobianyuanaichaobiaoqi',
    component: lanhu_chanpinzhongxinyalibiaobianyuanaichaobiaoqi
  },
  {
    path: '/lanhu_xinwenzixun',
    name: 'lanhu_xinwenzixun',
    component: lanhu_xinwenzixun
  },
  {
    path: '/lanhu_yingyonganli',
    name: 'lanhu_yingyonganli',
    component: lanhu_yingyonganli
  },
  {
    path: '/lanhu_chanpinzhongxin',
    name: 'lanhu_chanpinzhongxin',
    component: lanhu_chanpinzhongxin
  },
  {
    path: '/lanhu_guanjianjishu',
    name: 'lanhu_guanjianjishu',
    component: lanhu_guanjianjishu
  },
  {
    path: '/lanhu_chanpinzhongxinyunduanaichaobiaoqi',
    name: 'lanhu_chanpinzhongxinyunduanaichaobiaoqi',
    component: lanhu_chanpinzhongxinyunduanaichaobiaoqi
  },
  {
    path: '/lanhu_shouyebeifen3',
    name: 'lanhu_shouyebeifen3',
    component: lanhu_shouyebeifen3
  },
  {
    path: '/lanhu_guanyuwomen',
    name: 'lanhu_guanyuwomen',
    component: lanhu_guanyuwomen
  },
  {
    path: '/lanhu_xinwenzixun2',
    name: 'lanhu_xinwenzixun2',
    component: lanhu_xinwenzixun2
  },
  {
    path: '/lanhu_xinwenzixunxiangqingye',
    name: 'lanhu_xinwenzixunxiangqingye',
    component: lanhu_xinwenzixunxiangqingye
  },
  {
    path: '/lanhu_chanpinzhongxinbianyuanaichaobiaoqi',
    name: 'lanhu_chanpinzhongxinbianyuanaichaobiaoqi',
    component: lanhu_chanpinzhongxinbianyuanaichaobiaoqi
  },
  {
    path: '/lanhu_lijizixunbeifen',
    name: 'lanhu_lijizixunbeifen',
    component: lanhu_lijizixunbeifen
  },
  {
    path: '/lanhu_shouyebeifen2',
    name: 'lanhu_shouyebeifen2',
    component: lanhu_shouyebeifen2
  },
  {
    path: '/lanhu_xiazaizhongxin',
    name: 'lanhu_xiazaizhongxin',
    component: lanhu_xiazaizhongxin
  },
  {
    path: '/lanhu_shouye',
    name: 'lanhu_shouye',
    component: lanhu_shouye
  },
  {
    path: '/lanhu_lijizixun',
    name: 'lanhu_lijizixun',
    component: lanhu_lijizixun
  }
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  base: 'portal',
  routes
})

export default router
