<template>
  <div class="page flex-col">
    <div class="section_37 flex-row">
      <div class="box_73 flex-col">
        <img
          class="image_111"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
        />
        <div class="group_60 flex-row justify-between">
          <div class="image-wrapper_1 flex-row">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng5c62d00d96de04382f872ee4c5b6fe1d7b81b433a16d662fc7350da2a60692ba.png"
            />
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng47ec43f599d6917b1c1769b534c9deca86d8adb982f0356bb3b6d99239626f6c.png"
            />
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng47ec43f599d6917b1c1769b534c9deca86d8adb982f0356bb3b6d99239626f6c.png"
            />
          </div>
          <div class="section_38 flex-col justify-between">
            <span class="text_8">矩阵式AI抄表器</span>
            <div class="image-text_92 flex-row">
              <div class="image-text_93 flex-row justify-between">
                <div class="section_39 flex-col justify-between">
                  <div class="group_5 flex-col"></div>
                  <div class="group_6 flex-col"></div>
                  <div class="group_7 flex-col"></div>
                  <div class="group_8 flex-col"></div>
                  <div class="group_9 flex-col"></div>
                  <div class="group_10 flex-col"></div>
                  <div class="group_11 flex-col"></div>
                  <div class="group_12 flex-col"></div>
                </div>
                <span class="text-group_1">
                  识别准确率：99%以上
                  <br />
                  一个AI识别网关，最多可连接10台采集器
                  <br />
                  低功耗设计：10年内无需更换电池
                  <br />
                  通信模块：含10年通讯费
                  <br />
                  内外置双天线：可用在室内外/井下/楼宇竖井等不同环境
                  <br />
                  历史数据：可存储1年
                  <br />
                  防护等级：IP68可长期浸泡在水中正常工作
                  <br />
                  工业级设计：-30～60℃稳定运行
                  <br />
                  遮光盖设计：方便人工检查水表
                </span>
              </div>
              <div class="group_19 flex-col"></div>
            </div>
          </div>
        </div>
        <div class="group_61 flex-row">
          <div class="image-wrapper_2 flex-row">
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng2f1ac0a98c487091e0460436bc6a7fe204d8bf51f8b2dc3b15a8c54c49bb15c8.png"
            />
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngf65afb94323f41e1cdc82139dbeee535760ae4bf49fb3533121b284afa4f9730.png"
            />
            <img
              class="label_3"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngf65afb94323f41e1cdc82139dbeee535760ae4bf49fb3533121b284afa4f9730.png"
            />
          </div>
          <div class="box_1 flex-col">
            <img
              class="image_16"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngef10995a2f2f5430f1763dc13eb27d5db005e2c17ffa35d152fcc466a5414ad9.png"
            />
          </div>
          <div class="box_2 flex-col">
            <img
              class="image_17"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng8864afbca0e1dd27c3b629267ea2b9791867468e70da643dc02bd4e9c2832a5c.png"
            />
          </div>
          <div class="image-wrapper_4 flex-col">
            <img
              class="image_18"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng537c6783634e28f8188073b1247b3c5fad8d5bbd8f50b1dc6751ec2ae4213a3e.png"
            />
          </div>
          <span class="text_9">
            矩阵式AI抄表器由分布采集器和AI识别网关组成，适用于水表集中的场合，分布式采集器采集水表照片，AI识别网关进行智能识别和数据传输，具有低成本、低功耗、低资费等特点。
          </span>
        </div>
        <div class="group_62 flex-row justify-between">
          <div class="text-wrapper_31 flex-col"><span class="text_142">立即订购</span></div>
          <div class="text-wrapper_32 flex-col"><span class="text_143">资料下载</span></div>
        </div>
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng3d2d65076f31ce7ab05d198ab92825f1bc707c0a7cb3bc7c6d45e7ae125ac4e7.png"
        />
        <div class="group_15 flex-col">
          <span class="text_12">产品详情</span>
          <div class="image-text_94 flex-col justify-between">
            <img
              class="image_19"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngc9440b1fe300056905dbf8e580104085c0ae8b497ab54724329e0c1f9cd42003.png"
            />
            <span class="text-group_5">分布采集器&nbsp;正视图</span>
          </div>
          <div class="image-text_95 flex-col justify-between">
            <img
              class="image_20"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng9a6a97d2b45b63f5faba2f461f21cf55a2136bf6d5251c8dd910e62ef14bd8e1.png"
            />
            <span class="text-group_6">分布采集器&nbsp;侧视图</span>
          </div>
          <div class="image-text_96 flex-row">
            <div class="image-text_97 flex-col">
              <span class="text_13">
                矩阵式AI抄表器由分布采集器和AI识别网关组成，适用于水表集中的场合，分布式采集器采集水表照片，AI识别网关进行智能识别和数据传输，具有低成本、低功耗、低资费等特点。
              </span>
              <img
                class="image_8"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng848cc3825026c79e7e5ff7a1ffc44b116bc18d830dcbe4643b3bbf5051186a74.png"
              />
              <div class="text-wrapper_48 flex-row justify-between">
                <span class="text-group_2">AI识别网关&nbsp;正视图</span>
                <span class="text-group_3">AI识别网关&nbsp;侧视图</span>
              </div>
            </div>
            <img
              class="image_7"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnge77b6bab7c3049c884e85195d4c196690f814a8f6155de9afe7cb87eb9f8d908.png"
            />
          </div>
        </div>
        <div class="group_63 flex-col">
          <div class="box_74 flex-row">
            <img
              class="image_112"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
            />
            <div class="box_75 flex-col">
              <div class="image-wrapper_17 flex-row justify-between">
                <img
                  class="image_113"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
                />
                <img
                  class="image_114"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
                />
              </div>
              <img
                class="thumbnail_29"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
              />
              <img
                class="image_115"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
              />
            </div>
            <div class="text-wrapper_49 flex-col justify-between">
              <span class="text_239">周一至周五&nbsp;8：00-18:00</span>
              <span class="paragraph_32">
                北京总代理：北京迈瑞成科技有限公司
                <br />
                电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
                <br />
                邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
              </span>
            </div>
          </div>
          <div class="box_76 flex-row">
            <div class="block_37 flex-col justify-between">
              <img
                class="image_116"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
              />
              <span class="text_240">北京市海淀区翠微路甲3号</span>
            </div>
            <div class="text-group_43 flex-col justify-between">
              <span class="text_241">关键技术&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_242">下载中心&nbsp;&nbsp;&nbsp;|</span>
            </div>
            <div class="text-group_44 flex-col justify-between">
              <span class="text_243">产品中心&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_244">关于我们</span>
            </div>
            <span class="text_245">&nbsp;&nbsp;应用案例</span>
            <div class="block_38 flex-row">
              <div class="text-wrapper_50">
                <span class="text_246">售后服务：</span>
                <span class="paragraph_33">
                  010-57751726
                  <br />
                </span>
                <span class="text_247">商务合作：</span>
                <span class="text_248">010-83683458</span>
              </div>
              <span class="text_249">7*24小时售后客服热线</span>
            </div>
          </div>
        </div>
        <img
          class="image_9"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng6f578e45a32c8f716dfc070288916411c0e49240f920753b464d290c7e9a62fd.png"
        />
      </div>
      <div class="box_77 flex-row">
        <div class="image-text_98 flex-row justify-between">
          <img
            class="thumbnail_30"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
          />
          <span class="text-group_45">京公网安备&nbsp;11010802041940号</span>
        </div>
        <div class="image-text_99 flex-row justify-between">
          <img
            class="thumbnail_31"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
          />
          <span class="text-group_46">
            2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
          </span>
        </div>
      </div>
    </div>
    <div class="section_40 flex-row">
      <img
        class="image_117"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
      />
      <span class="text_250">首页</span>
      <span class="text_251">关键技术</span>
      <span class="text_252">产品中心</span>
      <span class="text_253">应用案例</span>
      <span class="text_254">下载中心</span>
      <span class="text_255">关于我们</span>
      <img
        class="label_24"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
      />
      <span class="text_256">400-123-8888</span>
      <img
        class="label_25"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
      />
      <span class="text_257">水务运营平台</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />