<template>
  <div class="page flex-col">
    <div class="section_39 flex-row">
      <div class="section_40 flex-col">
        <img
          class="image_91"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPngfaf843b5f6c04989ae8dba50ddd370be7f4ba6cf8399484708ec944443bc1cb5.png"
        />
        <div class="section_1 flex-col"></div>
        <div class="section_3 flex-col">
          <div class="group_54 flex-row justify-between">
            <div class="block_3 flex-row">
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngb7f26874f215886e56427ee6c06c995ebb30f36feb195bc8c5e8d019a63d73ea.png"
              />
              <span class="text_8">商务合作</span>
            </div>
            <div class="block_4 flex-row justify-between">
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng5457472c1321aab3378f527f0ceb613b8bc893ff3a74021d230677db6f821d0e.png"
              />
              <span class="text_9">其他合作</span>
            </div>
          </div>
          <span class="text_10">业务合作/产品需求等</span>
          <div class="group_55 flex-row justify-between">
            <span class="text_11">联系人</span>
            <div class="text-wrapper_2 flex-col"><span class="text_12">请输入联系人姓名</span></div>
          </div>
          <div class="group_56 flex-row justify-between">
            <div class="text-wrapper_3">
              <span class="text_13">*</span>
              <span class="text_14">联系电话</span>
            </div>
            <div class="text-wrapper_4 flex-col"><span class="text_15">请输入联系人手机号</span></div>
          </div>
          <div class="group_57 flex-row justify-between">
            <div class="text-wrapper_5">
              <span class="text_16">*</span>
              <span class="text_17">联系邮箱</span>
            </div>
            <div class="text-wrapper_6 flex-col"><span class="text_18">请输入联系人邮箱</span></div>
          </div>
          <div class="group_58 flex-row justify-between">
            <span class="text_19">留言</span>
            <div class="block_5 flex-col"></div>
          </div>
          <div class="text-wrapper_7 flex-col"><span class="text_20">提交</span></div>
        </div>
        <div class="block_42 flex-col">
          <div class="box_67 flex-row">
            <img
              class="image_92"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng26cae9c56efd79eb07dd1bd390e9884c2ddad7df6764e92c7f8015176f72c14b.png"
            />
            <div class="box_68 flex-col">
              <div class="image-wrapper_15 flex-row justify-between">
                <img
                  class="image_93"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng56f9d4d280f2389a1aafb712679dd147b482aed64d9afc8b47d4ad150b0b1cba.png"
                />
                <img
                  class="image_94"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng8e382930868646b45a886aefb1abf841a0e099abcd7ff5781eb19e2f529dace4.png"
                />
              </div>
              <img
                class="thumbnail_28"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng46de67218b2b7de6cffa052071f8772437e3c1b52982d3a360ec34a3422e399c.png"
              />
              <img
                class="image_95"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2f3c91312a5633336d25f2c48990e4dc21002a03a7d0ac697ff7f77ce64d32b.png"
              />
            </div>
            <div class="text-wrapper_36 flex-col justify-between">
              <span class="text_229">周一至周五&nbsp;8：00-18:00</span>
              <span class="paragraph_30">
                北京总代理：北京迈瑞成科技有限公司
                <br />
                电&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;话：18600234423
                <br />
                邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱：liqing&#64;miruicheng.com
              </span>
            </div>
          </div>
          <div class="box_69 flex-row">
            <div class="block_43 flex-col justify-between">
              <img
                class="image_96"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng93686220e7cb30a71b056ab3806db4f888d862d39c8bb582a07d9537d9638a48.png"
              />
              <span class="text_230">北京市海淀区翠微路甲3号</span>
            </div>
            <div class="text-group_36 flex-col justify-between">
              <span class="text_231">关键技术&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_232">下载中心&nbsp;&nbsp;&nbsp;|</span>
            </div>
            <div class="text-group_37 flex-col justify-between">
              <span class="text_233">产品中心&nbsp;&nbsp;&nbsp;|</span>
              <span class="text_234">关于我们</span>
            </div>
            <span class="text_235">&nbsp;&nbsp;应用案例</span>
            <div class="block_44 flex-row">
              <div class="text-wrapper_37">
                <span class="text_236">售后服务：</span>
                <span class="paragraph_31">
                  010-57751726
                  <br />
                </span>
                <span class="text_237">商务合作：</span>
                <span class="text_238">010-83683458</span>
              </div>
              <span class="text_239">7*24小时售后客服热线</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section_41 flex-row">
        <div class="image-text_17 flex-row justify-between">
          <img
            class="thumbnail_29"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng10ca6e93b34cc66044fd986dfe30d1824b733f565754e9593a2da19c25ceb976.png"
          />
          <span class="text-group_38">京公网安备&nbsp;11010802041940号</span>
        </div>
        <div class="image-text_18 flex-row justify-between">
          <img
            class="thumbnail_30"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng8c8557aa246ecf968605a33900188d770e57a7749bda7c0198acd6a05978455d.png"
          />
          <span class="text-group_39">
            2023-2025&nbsp;鸿成鑫鼎&nbsp;&nbsp;版权所有｜京ICP备2023010548号-1
          </span>
        </div>
      </div>
    </div>
    <div class="section_42 flex-row">
      <img
        class="image_97"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngdddc32980bd651e6eff598485fb02da55f7c3d4b0456aacf2eb4513b0a8c1e04.png"
      />
      <span class="text_240">首页</span>
      <span class="text_241">关键技术</span>
      <span class="text_242">产品中心</span>
      <span class="text_243">应用案例</span>
      <span class="text_244">下载中心</span>
      <span class="text_245">关于我们</span>
      <img
        class="label_24"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng234a648a4e39f12504eb0ce70a154f4b9186b260cd7db5521324f3bfd60c0d07.png"
      />
      <span class="text_246">400-123-8888</span>
      <img
        class="label_25"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng31840694f315e1200280e327ca707ae45cfb8c394e4d630a17e2e1f5806f74dc.png"
      />
      <span class="text_247">水务运营平台</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />